import React, { Component } from 'react';
import { Form, Row, Col, Card, Button, Modal } from 'react-bootstrap';
import { Redirect } from "react-router-dom";
import { Fetcher } from "../../../Helpers/fetcher.js";
import { cloneDeep, isEmpty } from 'lodash';
import "react-datepicker/dist/react-datepicker.css";
import "./index.css";

class CreateTrunk extends Component {
	constructor(props) {
		super(props);

		let sitePath;
		let filePath;
		
		const protocol = window.location.protocol;
		const slashes = protocol.concat("//");
		sitePath = slashes.concat(window.location.hostname);
		this.state = {
			
			apiPath: props.app.apiPath,
			sitePath: sitePath,
			filePath: filePath,
			dialerList: [],
			showModal: false,
			modalHeading: 'Status',
			modalContent: '',
			trunkCreated: false,
			dialerId: '',
			trunkName: '',
			pilotNumber: '',
			channels: '',
			cps: ''
		}

	}

	componentDidMount() {
		const apiPath = this.state.apiPath;
		
		let apiUrl = '';
		apiUrl = apiPath + '/api/obd/dialers/';
		
		Fetcher.get(apiUrl)
			.then(res => {
				this.setState({
					dialerList: res
				})
			})
			.catch(err => { console.log('Error in fetching Sender Id\'s', err) });
	}

	setvalue = (e) => {
		this.setState({
			[e.target.name]: e.target.value,
		});
	}

	setMessageShow = (status) => {
		const trunkCreated = this.state.trunkCreated;
		this.setState({
			showModal: status,
			redirectToSummary: trunkCreated
		});
	}

	// Add CLI
	handleCreateTrunkSubmit = async (event) => {
		event.preventDefault();
		const formData = cloneDeep(this.state);
		console.log(formData);
		const data = {
			"trunkName": formData.trunkName,
			"pilotNumber": formData.pilotNumber,
			"dialerId": this.state.dialerId,
			"channels": this.state.channels,
			"cps": this.state.cps
		}
		this.addNewTrunk(data);
	}

	addNewTrunk = (data) => {
		console.log(data);
		return Fetcher.post(this.state.apiPath + '/api/obd/trunk', { headers: { "Content-type": "application/json" }, method: 'POST', body: JSON.stringify(data) })
			.then(res => {
				let modalHeading;
				let trunkCreated = false;
				if (res.message === 'Parameters Incorrect' || 'Trunk Name already exists') {
					modalHeading = 'Error';
				} else {
					alert(res.message);
					modalHeading = 'Success';
					trunkCreated = true;
				}
				this.setState({
					showModal: true,
					modalHeading: modalHeading,
					modalContent: res.message,
					trunkCreated: trunkCreated
				})

			})
			.catch(err => {
				console.log('Error in Creating Trunk', err)
				this.setState({
					showModal: true,
					modalHeading: 'Error',
					modalContent: err.message,
					trunkCreated: false
				})
			});
	}

	render() {

		if (this.state.trunkCreated === true) {
			return <Redirect to={'/trunk-list/'} />
		}

		const dialerList = cloneDeep(this.state.dialerList);

		let submitButtonlabel = 'Create Trunk';

		let dialerDropdown = '';
		if (!isEmpty(dialerList) && (!('error' in dialerList))) {
			dialerDropdown = Array.isArray(dialerList) ? dialerList.map((obj, index) => {
				return <option value={obj.dialerId} key={`senderid${index}`} >{obj.dialerName}</option>
			}) : <option value="" >No Dialer found</option>

		} else {
			dialerDropdown = <option value="" >No Dialer found</option>
		}

		return (

			<Form className="add-campaign-form" onSubmit={(e) => this.handleCreateTrunkSubmit(e)}>
				<Row>
					<Col sm={12}>
						<Card>
							<Card.Header>Create Trunk</Card.Header>
							<Card.Body>
								<Row>
									<Col sm={4}>
										<Form.Group>
											<Form.Control required name="trunkName" type="text" placeholder="Trunk Name" onChange={e => this.setvalue(e)} value={this.state.trunkName} />
										</Form.Group>
									</Col>
								</Row>
								<Row>
									<Col sm={4}>
										<Form.Group>
											<Form.Control required name="pilotNumber" type="text" placeholder="Pilot Number" onChange={e => this.setvalue(e)} value={this.state.pilotNumber} />
										</Form.Group>
									</Col>
								</Row>
								<Row>
									<Col sm={4}>
										<Form.Group>
											<Form.Select required name="dialerId" onChange={e => this.setvalue(e)} as="select">
												<option value="">Select Dialer</option>
												{dialerDropdown}
											</Form.Select>
										</Form.Group>
									</Col>
								</Row>
								<Row>
									<Col sm={4}>
										<Form.Group>
											<Form.Control required name="channels" type="text" placeholder="Channels" onChange={e => this.setvalue(e)} value={this.state.channels} />
										</Form.Group>
									</Col>
								</Row>
								<Row>
									<Col sm={4}>
										<Form.Group>
											<Form.Control required name="cps" type="text" placeholder="CPS" onChange={e => this.setvalue(e)} value={this.state.cps} />
										</Form.Group>
									</Col>
								</Row>
								<Button variant="primary" type="submit">{submitButtonlabel}</Button>
							</Card.Body>
						</Card>

					</Col>
				</Row>
				<Modal size="sm" show={this.state.showModal} onHide={() => this.setMessageShow(false)}>
					<Modal.Header closeButton>
						<Modal.Title id="example-modal-sizes-title-sm">
							{this.state.modalHeading}
						</Modal.Title>

					</Modal.Header>
					<Modal.Body>{this.state.modalContent} </Modal.Body>
				</Modal>

			</Form>
		);
	}
}

export default CreateTrunk;