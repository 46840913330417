import React, { Component } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { cloneDeep } from 'lodash';
import { Fetcher } from "../../../Helpers/fetcher.js";
import { Redirect } from "react-router-dom";


// import "./index.css";
class AddCLI extends Component {
	constructor(props) {
		super(props);
		this.state = {
			cli: '',
			cliid: '',
			channels: '',
			trunkName: '',
			parent: '',
			redirectToCliManagement: false,
			showModal: false,
			modalHeading: 'Status',
			modalContent: '',
		};

	}
	componentDidMount() {
	}


	setvalue = (e) => {
		this.setState({
			[e.target.name]: e.target.value,
		});
	}
	hideModal = () => {
		this.setState({
			showModal: false,
			redirectToCliManagement: this.state.redirectToCliManagement
		});
	}


	addCli = (event) => {
		event.preventDefault();
		const state = cloneDeep(this.state);

		const data = {
			cli: state.cli,
			channels: state.channels,
			trunkName: state.trunkName
		}
		this.saveData(data);

	}
	saveData = (data) => {
		return Fetcher.post(this.props.app.apiPath + '/api/obd/cli', { headers: { "Content-type": "application/json" }, method: 'POST', body: JSON.stringify(data) })
			.then(res => {
				let modalHeading;
				let redirectToCliManagement;
				if (res.message === 'Parameters Incorrect' || res.message === 'Request Failed!! Please try again') {
					modalHeading = 'Error';
					redirectToCliManagement = false;
				} else {
					modalHeading = 'Success';
					redirectToCliManagement = true;
					alert(res.message);

				}
				this.setState({
					showModal: true,
					modalHeading: modalHeading,
					modalContent: res.message,
					redirectToCliManagement: redirectToCliManagement
				})

			})
			.catch(err => {
				console.log('Error in Adding CLI', err)
				this.setState({
					showModal: true,
					modalHeading: 'Error',
					modalContent: err.message,
				})
			});

	}

	render() {
		if (this.state.redirectToCliManagement === true) {
			return <Redirect to={'/cli-list/'} />
		}

		return (

			<Form method="post" onSubmit={this.addCli}>
				<Row>
					<Col sm={12}>
						<Card>
							<Card.Header>Add CLI</Card.Header>
							<Card.Body>
								<Row>
									<Col sm={4}>
										<Form.Group>
											<Form.Control required name="cli" type="text" placeholder="CLI" onChange={e => this.setvalue(e)} value={this.state.cli} />
										</Form.Group>
									</Col>
									<Col sm={4}>
										<Form.Group>
											<Form.Control required name="channels" type="text" placeholder="Channels" onChange={e => this.setvalue(e)} value={this.state.channels} />
										</Form.Group>
									</Col>
									<Col sm={4}>
										<Form.Group>
											<Form.Control required name="trunkName" type="text" placeholder="Trunk Name" onChange={e => this.setvalue(e)} value={this.state.trunkName} />
										</Form.Group>
									</Col>
								</Row>
								<Button className="btn-round" variant="primary" type="submit">Add CLI</Button>
							</Card.Body>
						</Card>
					</Col>
				</Row>
			</Form>
		);
	}

}
export default AddCLI;