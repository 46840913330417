import React, { Component } from 'react';
import { Form, Row, Col, Card, Button, Modal } from 'react-bootstrap';
import { Redirect } from "react-router-dom";
import { Fetcher } from "../../../Helpers/fetcher.js";
import { cloneDeep } from 'lodash';
import "react-datepicker/dist/react-datepicker.css";
import "./index.css";

class CreateDialer extends Component {
	constructor(props) {
		super(props);

		
		let sitePath;
		let filePath;
		
		const protocol = window.location.protocol;
		const slashes = protocol.concat("//");
		sitePath = slashes.concat(window.location.hostname);
		this.state = {
			apiPath: props.app.apiPath,
			sitePath: sitePath,
			filePath: filePath,
			showModal: false,
			modalHeading: 'Status',
			modalContent: '',
			dialerCreated: false,
			dialerName: '',
			dialerIP: '',
			dialerPort: ''
		}

	}

	componentDidMount() {
		
	}

	setvalue = (e) => {
		this.setState({
			[e.target.name]: e.target.value,
		});
	}

	setMessageShow = (status) => {
		const dialerCreated = this.state.dialerCreated;
		this.setState({
			showModal: status,
			redirectToSummary: dialerCreated
		});
	}

	// Create Dialer
	handleCreateDialerSubmit = async (event) => {
		event.preventDefault();
		const formData = cloneDeep(this.state);
		console.log(formData);
		const data = {
			"dialerName": formData.dialerName,
			"dialerIP": formData.dialerIP,
			"dialerPort": formData.dialerPort
		}
		this.addNewDialer(data);
	}

	addNewDialer = (data) => {
		console.log(data);
		return Fetcher.post(this.state.apiPath + '/api/obd/dialer', { headers: { "Content-type": "application/json" }, method: 'POST', body: JSON.stringify(data) })
			.then(res => {
				let modalHeading;
				let dialerCreated = false;
				if (res.message === 'Parameters Incorrect' || 'Dialer Name already exists') {
					modalHeading = 'Error';
				} else {
					alert(res.message);
					modalHeading = 'Success';
					dialerCreated = true;
				}
				this.setState({
					showModal: true,
					modalHeading: modalHeading,
					modalContent: res.message,
					dialerCreated: dialerCreated
				})

			})
			.catch(err => {
				console.log('Error in Creating Dialer', err)
				this.setState({
					showModal: true,
					modalHeading: 'Error',
					modalContent: err.message
				})
			});
	}

	render() {

		if (this.state.dialerCreated === true) {
			return <Redirect to={'/dialer-list/'} />
		}

		let submitButtonlabel = 'Create Dialer';

		return (

			<Form className="add-campaign-form" onSubmit={(e) => this.handleCreateDialerSubmit(e)}>
				<Row>
					<Col sm={12}>
						<Card>
							<Card.Header>Create Dialer</Card.Header>
							<Card.Body>
								<Row>
									<Col sm={4}>
										<Form.Group>
											<Form.Control required name="dialerName" type="text" placeholder="Dialer Name" onChange={e => this.setvalue(e)} value={this.state.dialerName} />
										</Form.Group>
									</Col>
								</Row>
								<Row>
									<Col sm={4}>
										<Form.Group>
											<Form.Control required name="dialerIP" type="text" placeholder="IP" onChange={e => this.setvalue(e)} value={this.state.dialerIP} />
										</Form.Group>
									</Col>
								</Row>
								<Row>
									<Col sm={4}>
										<Form.Group>
											<Form.Control required name="dialerPort" type="text" placeholder="Port" onChange={e => this.setvalue(e)} value={this.state.dialerPort} />
										</Form.Group>
									</Col>
								</Row>
								<Button variant="primary" type="submit">{submitButtonlabel}</Button>
							</Card.Body>
						</Card>

					</Col>
				</Row>
				<Modal size="sm" show={this.state.showModal} onHide={() => this.setMessageShow(false)}>
					<Modal.Header closeButton>
						<Modal.Title id="example-modal-sizes-title-sm">
							{this.state.modalHeading}
						</Modal.Title>

					</Modal.Header>
					<Modal.Body>{this.state.modalContent} </Modal.Body>
				</Modal>

			</Form>
		);
	}
}

export default CreateDialer;