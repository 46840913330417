import React, { Component } from 'react';
import { Form, Row, Col, Card, Button, Modal } from 'react-bootstrap';
import { Redirect } from "react-router-dom";
import { Fetcher } from "../../../Helpers/fetcher.js";
import { cloneDeep, isEmpty } from 'lodash';
import "react-datepicker/dist/react-datepicker.css";
import "./index.css";

class UploadCLIFile extends Component {
	constructor(props) {
		super(props);
		this.cliFileInput = React.createRef();
		let sitePath;
		let filePath;

		const protocol = window.location.protocol;
		const slashes = protocol.concat("//");
		sitePath = slashes.concat(window.location.hostname);

		this.state = {
			cliFileType: 'newcliFile',
			cliFileInput: this.cliFileInput,
			userId: props.app.userId,
			apiPath: props.app.apiPath,
			sitePath: sitePath,
			filePath: filePath,
			showModal: false,
			modalHeading: 'Status',
			modalContent: '',
			cliUploaded: false,
			pool: 'shared'
			
		}

	}

	componentDidMount() {
	}

	toggleChange = (e, name) => {
		this.setState({
			[name]: !this.state[name],
		});
	}


	setvalue = (e) => {
		this.setState({
			[e.target.name]: e.target.value,
		});
	}

	setUploadType = (type, uploadTypeKey) => {
		this.setState({
			[uploadTypeKey]: type,
		});
	}

	setMessageShow = (status) => {
		this.setState({
			showModal: status
		});
	}

	onFileChangeHandler = event => {
		//if ( this.checkMimeType( event ) && this.checkFileSize( event ) ) {
		if (this.checkMimeType(event)) {

		}
	}
	// Add Voice File

	handleAddCliFileSubmit = (event) => {
		event.preventDefault();
		const formData = cloneDeep(this.state);
		console.log(formData);

		const cliFile = formData.cliFileInput.current.files[0];
		const fd = new FormData();
		fd.append('cliFile', cliFile);
		fd.append('pool', formData.pool);

		return Fetcher.post(formData.apiPath + '/api/obd/cliupload', { method: 'POST', body: fd })
			.then(res => {
				let modalHeading;
				let cliUploaded = false;
				if (res.message === 'Parameters Incorrect') {
					modalHeading = 'Error';
				} else {
					alert(res.message);
					modalHeading = 'Success';
					cliUploaded = true;
				}
				this.setState({
					showModal: true,
					modalHeading: modalHeading,
					modalContent: res.message,
					cliUploaded: cliUploaded
				})
			})
			.catch(err => {
				console.log('Error in uploading CLI File to Server', err);
				this.setState({
					showModal: true,
					modalHeading: 'Error',
					modalContent: err.message
				})
			});
	}

	addZero = (i) => {
		if (i < 10) {
			i = "0" + i;
		}
		return i;
	}

	getFileNameFromUrl = (url) => {
		var n = url.lastIndexOf('/');
		return url.substring(n + 1);
	}

	checkMimeType = (event) => {
		let files = event.target.files
		let allowedTypes = ['text/plain', 'text/csv'];
		if (event.target.name === 'newContactsFile') {
			allowedTypes = ['text/plain', 'text/csv'];
		}
		let err = [] // create empty array

		for (var x = 0; x < files.length; x++) {
			if (allowedTypes.every(type => files[x].type !== type)) {
				err[x] = files[x].type + ' is not a supported format\n';
				// assign message to array
			}
		};
		for (var z = 0; z < err.length; z++) { // loop create toast massage
			event.target.value = null
			this.setState({
				showModal: true,
				modalHeading: 'Error',
				modalContent: err[z]
			})
			//toast.error( err[ z ] )
		}
		return true;
	}

	checkFileSize = (event) => {
		let allowedSize = 1;
		if (event.target.name === 'newContactsFile') {
			allowedSize = 20;
		}

		let files = event.target.files
		let err = [];
		for (var x = 0; x < files.length; x++) {
			if (((files[x].size / 1024 / 1024).toFixed(2)) > allowedSize) {
				err[x] = files[x].size + ' is too large, please pick a smaller file\n';
			}
		};
		for (var z = 0; z < err.length; z++) {
			//console.log( err )
			this.setState({
				showModal: true,
				modalHeading: 'Error',
				modalContent: err[z]
			})
			event.target.value = null
		}
		return true;
	}



	render() {

		let submitButtonlabel = 'Upload File';
		if (this.state.cliUploaded === true) {
			return <Redirect to={'/cli-list/'} />
		}

		return (

			<Form className="add-campaign-form" onSubmit={(e) => this.handleAddCliFileSubmit(e)}>
				<Row>
					<Col sm={12}>
						<Card>
							<Card.Header>Upload CLI File</Card.Header>
							<Card.Body>
								<Row>
									<Col sm={4}>
										<Form.Label>Upload CLI File(.txt OR .csv format only)</Form.Label>
										<Form.Group controlId="cli-file-upload">
											<Form.Control required name="newcliFile" onChange={this.onFileChangeHandler} ref={this.cliFileInput} type="file" />
										</Form.Group>
									</Col>
								</Row>
								<Button variant="primary" type="submit">{submitButtonlabel}</Button>
							</Card.Body>
						</Card>
					</Col>
				</Row>
				<Modal size="sm" show={this.state.showModal} onHide={() => this.setMessageShow(false)}>
					<Modal.Header closeButton>
						<Modal.Title id="example-modal-sizes-title-sm">
							{this.state.modalHeading}
						</Modal.Title>

					</Modal.Header>
					<Modal.Body>{this.state.modalContent} </Modal.Body>
				</Modal>
			</Form>
		);
	}
}

export default UploadCLIFile;