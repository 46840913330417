import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Button, Modal, Card, Col, Form, Row } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit';
import { cloneDeep, isEmpty } from 'lodash';
import paginationFactory, { PaginationProvider, PaginationListStandalone, SizePerPageDropdownStandalone } from 'react-bootstrap-table2-paginator';
import { Fetcher } from "../../Helpers/fetcher.js";
import 'bootstrap/dist/css/bootstrap.css';
import Dropdown from 'react-bootstrap/Dropdown';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';

import "./index.css";
class UserManagement extends Component {
	constructor(props) {

		super(props);
		this.state = {
			userList: [],
			adminCredits: 0,
			creditsToAdd: 0,
			creditsToRemove: 0,
			confirmDelete: false,
			confirmRemoveCLI: false,
			selectedUserId: '',
			selectedCliId: '',
			showAddCreditModal: false,
			showRemoveCreditModal: false,
			showModal: false,
			modalHeading: 'Status',
			modalContent: '',
			password: '',
			confirmPassword: '',
			showPwdModal: false,
			cliAvailableList: [],
			cliAllocatedList: [],
			showAllocateCLIModal: false,
			showCLIModal: false,
			cliId: '',
			cli: '',
			cliRows: [],
			cliList: ''
		}
	}
	componentDidMount() {
		this.getUsers();
	}


	getUsers = () => {
		const apiPath = this.props.app.apiPath;
		const userId = this.props.app.userId;
		Fetcher.get(apiPath + '/api/obd/user/' + userId)
			.then(res => {
				this.setState({
					userList: res.users,
					adminCredits: res.adminCredits,
					showModal: false,
					showAddCreditModal: false,
					showRemoveCreditModal: false,
					showPwdModal: false,
					confirmDelete: false,
					showAllocateCLIModal: false,
					showCLIModal: false,
					confirmRemoveCLI: false
				})
			})
			.catch(err => {
				console.log('Error in fetching Users List', err);
			});
	}

	confirmDelete = (status, userId) => {
		this.setState({
			confirmDelete: status,
			selectedUserId: userId
		});
	}

	confirmRemoveCLI = (status, cliId) => {
		this.setState({
			confirmRemoveCLI: status,
			selectedCliId: cliId
		});
	}

	deleteUser = () => {
		const userId = this.state.selectedUserId;
		const data = {
			parent: this.props.app.userId,
		}

		return Fetcher.post(this.props.app.apiPath + '/api/obd/user/' + userId, { headers: { "Content-type": "application/json" }, method: 'DELETE', body: JSON.stringify(data) })
			.then(res => {
				alert(res.message);
				this.getUsers();
			})
			.catch(err => {
				console.log('Error in Deleting User', err)

				this.setState({
					showModal: true,
					modalHeading: 'Error',
					modalContent: 'Error in Deleting User, please try again',

				})
			});
	}

	removeCLI = () => {
		const cliId = this.state.selectedCliId;
		const data = {
			cliId: cliId,
		}

		return Fetcher.post(this.props.app.apiPath + '/api/obd/cli/deallocate/', { headers: { "Content-type": "application/json" }, method: 'DELETE', body: JSON.stringify(data) })
			.then(res => {
				alert(res.message);
				this.getUsers();
			})
			.catch(err => {
				console.log('Error in Removing CLI', err)

				this.setState({
					showModal: true,
					modalHeading: 'Error',
					modalContent: 'Error in Removing CLI, please try again',

				})
			});
	}

	showPassword = (userId) => {
		const apiPath = this.props.app.apiPath;
		Fetcher.get(apiPath + '/api/obd/pwd/' + userId)
			.then(res => {
				alert(res.password);
			})
			.catch(err => {
				console.log('Error in fetching Password', err);
			});

	}
	addCredits = (event) => {
		event.preventDefault();
		if (this.state.creditsToAdd <= 0) {
			alert('Recharge Amount cannot be less than or equal to 0');
			return;
		}
		const data = {
			userId: this.state.selectedUserId,
			parent: this.props.app.userId,
			credits: this.state.creditsToAdd,
		}
		return Fetcher.post(this.props.app.apiPath + '/api/obd/credits/add', { headers: { "Content-type": "application/json" }, method: 'POST', body: JSON.stringify(data) })
			.then(res => {
				if (res.message === 'Parameters Incorrect') {
					alert('Recharge Failed.')
				} else {
					alert(res.message)
				}
				this.setState({
					creditsToAdd: 0
				})
				//this.getUsers();
				window.location.reload(true);
			})
			.catch(err => {
				console.log('Error in Recharge', err)
				this.setState({
					showModal: true,
					modalHeading: 'Error',
					modalContent: err.message,

				})
			});
	}

	removeCredits = (event) => {
		event.preventDefault();
		if (this.state.creditsToRemove <= 0) {
			alert('Recharge amount cannot be less than or equal to 0');
			return;
		}
		const data = {
			userId: this.state.selectedUserId,
			parent: this.props.app.userId,
			credits: this.state.creditsToRemove,
		}
		return Fetcher.post(this.props.app.apiPath + '/api/obd/credits/remove', { headers: { "Content-type": "application/json" }, method: 'POST', body: JSON.stringify(data) })
			.then(res => {
				if (res.message === 'Parameters Incorrect') {
					alert('Recharge Failed.')
				} else {
					alert(res.message)
				}
				this.setState({
					creditsToRemove: 0
				})
				//this.getUsers();
				window.location.reload(true);
			})
			.catch(err => {
				console.log('Error in Recharge', err)
				this.setState({
					showModal: true,
					modalHeading: 'Error',
					modalContent: err.message,

				})
			});
	}

	resetPassword = (event) => {
		event.preventDefault();
		if (this.state.confirmPassword !== this.state.password) {
			alert('Password & Confim Password don\'t match');
			return;
		}
		const data = {
			userId: this.state.selectedUserId,
			parent: this.props.app.userId,
			password: this.state.password,
		}
		return Fetcher.post(this.props.app.apiPath + '/api/obd/pwd/reset', { headers: { "Content-type": "application/json" }, method: 'POST', body: JSON.stringify(data) })
			.then(res => {
				if (res.message === 'Parameters Incorrect') {
					alert('Reset Password Failed')
				} else {
					alert(res.message)
				}
				this.getUsers();
			})
			.catch(err => {
				console.log('Error in Reset Password', err)
				this.setState({
					showModal: true,
					modalHeading: 'Error',
					modalContent: err.message,

				})
			});
	}

	showAllocateCLIModal = (userId) => {
		this.setState({
			showAllocateCLIModal: true,
			selectedUserId: userId
		});

		this.getAvailableCli(userId);
	}

	showCLIModal = (userId) => {
		this.setState({
			showCLIModal: true,
			selectedUserId: userId
		});

		this.getAllocatedCli(userId);
	}

	getAllocatedCli = (userId) => {
		const apiPath = this.props.app.apiPath;

		Fetcher.get(apiPath + '/api/obd/cli/user/'+userId)
			.then(res => {
				this.setState({
					cliAllocatedList: res,
					showModal: false
				})
			})
			.catch(err => { console.log('Error in fetching Available CLI List', err) });
	}

	getAvailableCli = (userId) => {
		const apiPath = this.props.app.apiPath;

		Fetcher.get(apiPath + '/api/obd/cli/available')
			.then(res => {
				this.setState({
					cliAvailableList: res,
					showModal: false
				})
			})
			.catch(err => { console.log('Error in fetching Available CLI List', err) });
	}

	allocateCLI = (event) => {
		event.preventDefault();
		const state = cloneDeep(this.state);
		if (this.state.cliList === '') {
			alert("Please add CLI Row");
			return;
		}
		const data = {
			"cliRows": JSON.stringify(this.state.cliList),
		}
		return Fetcher.post(this.props.app.apiPath + '/api/obd/allocate/cli', { headers: { "Content-type": "application/json" }, method: 'POST', body: JSON.stringify(data) })
			.then(res => {
				if (res.message === 'Parameters Incorrect') {
					alert(res.message);
				} else {
					alert(res.message)
				}
				this.setState({
					cliRows: [],
					cliList: '',
					cli: '',
					cliId: ''

				})
				this.getUsers();
			})
			.catch(err => {
				console.log('Error in Changing CLI', err)
				this.setState({
					showModal: true,
					modalHeading: 'Error',
					modalContent: err.message,

				})
			});
	}

	handleChange = idx => e => {
		const { name, value } = e.target;
		const cliRows = [...this.state.cliRows];
		cliRows[idx] = {
			[name]: value
		};
		this.setState({
			cliRows
		});
	};

	handleRemoveRow = () => {
		this.setState({
			cliRows: this.state.cliRows.slice(0, -1)
		});
	};

	handleAddRow = () => {
		if (this.state.cliId !== '') {
			let cli = this.state.cliId.substring(this.state.cliId.lastIndexOf(',')+1,this.state.cliId.length);
			let cliId = this.state.cliId.substring(0,this.state.cliId.lastIndexOf(','));
			let userId = this.state.selectedUserId;
			const item = {
				cli: cli,
				cliId: cliId,
				userId: ''+userId
			};
		const checkCli = obj => obj.cli === cli;
			if(this.state.cliRows.some(checkCli))
			{
					alert(cli+ ' is already selected');
					return;
			}
			this.state.cliRows.push(item);
			this.setState({
				cliRows: this.state.cliRows
			});
			this.state.cliList = {
				"cliList": this.state.cliRows
			}
		}
		else {
			alert("Please select CLI");
		}
	};

	setvalue = (e) => {
		this.setState({
			[e.target.name]: e.target.value,
		});
	}

	setMessageShow = (status) => {
		this.setState({
			showModal: false,
			showAddCreditModal: false,
			showRemoveCreditModal: false,
			showPwdModal: false,
			showAllocateCLIModal: false,
			showCLIModal: false,
		});
	}

	showAddCreditModal = (userId) => {
		this.setState({
			showAddCreditModal: true,
			selectedUserId: userId
		});
	}
	showRemoveCreditModal = (userId) => {
		this.setState({
			showRemoveCreditModal: true,
			selectedUserId: userId
		});
	}

	showPwdModal = (userId) => {
		this.setState({
			showPwdModal: true,
			selectedUserId: userId
		});
	}


	render() {

		const cliRows = cloneDeep(this.state.cliRows);
		const cliTable = Array.isArray(cliRows) ? cliRows.map((item, index) => {
			return <tr key={index}>
				<td>{this.state.cliRows[index].cli}</td>
				<td>
					<Button className="btn-sm" onClick={() => this.handleRemoveRow()} variant="primary">Remove CLI</Button>
				</td>
			</tr>;
		}) : [];

		const availableCliList = cloneDeep(this.state.cliAvailableList);
		let availableCliDropdown = '';
		if (!isEmpty(availableCliList) && (!('error' in availableCliList))) {
			availableCliDropdown = availableCliList.map((obj, index) => {
				return <option value={obj.cliId+','+obj.cli} key={`cli${index}`} >{obj.cli}</option>
			})

		} else {
			availableCliDropdown = <option value="" >No ID found</option>
		}

		const allocatedCliList = cloneDeep(this.state.cliAllocatedList);
		const allocatedCliTable = Array.isArray(allocatedCliList) ? allocatedCliList.map((item, index) => {
			return <tr key={index}>
				<td>{allocatedCliList[index].cli}</td>
				<td>
					<Button className="btn-sm" key={`${index}-delete-cli`} onClick={(e) => { this.confirmRemoveCLI(true, allocatedCliList[index].cliId) }} variant="primary">Remove CLI</Button>
				</td>
			</tr>;
		}) : [];

		const userList = this.state.userList;
		var columns;
		if (this.props.app.role === 'ROLE_SADMIN') {
			columns = [
				{
					dataField: 'userId',
					text: 'User Id',
					sort: true,
					hidden: true
				},
				{
					dataField: 'username',
					text: 'Username',
					sort: true,
					searchable: true
				},
				{
					dataField: 'name',
					text: 'Name',
					sort: true,
					searchable: true
				}, {
					dataField: 'emailId',
					text: 'Email Id',
					sort: true,
					searchable: true
				}, {
					dataField: 'number',
					text: 'Mobile',
					sort: true,
					searchable: false
				}, {
					dataField: 'company',
					text: 'Company',
					sort: true,
					searchable: false
				}, {
					dataField: 'userType',
					text: 'Type',
					sort: true,
					searchable: false
				}, {
					dataField: 'credits',
					text: 'Balance(Rs)',
					sort: false,
					searchable: false
				},{
					dataField: 'planName',
					text: 'Voice Plan',
					sort: false,
					searchable: false
				},{
					dataField: 'parentUser',
					text: 'Parent',
					sort: false,
					searchable: false
				},{
					dataField: 'expiryDate',
					text: 'User Expiry',
					sort: false,
					searchable: false
				},{
					dataField: 'usercli',
					text: 'CLI List',
					sort: false,
					searchable: false
				},{
					dataField: 'action',
					text: 'Action',
					sort: false,
					searchable: false
				},];
		}
		else {
			columns = [
				{
					dataField: 'userId',
					text: 'User Id',
					sort: true,
					hidden: true
				},
				{
					dataField: 'username',
					text: 'Username',
					sort: true,
					searchable: true
				},
				{
					dataField: 'name',
					text: 'Name',
					sort: true,
					searchable: true
				}, {
					dataField: 'emailId',
					text: 'Email Id',
					sort: true,
					searchable: true
				}, {
					dataField: 'number',
					text: 'Mobile',
					sort: true,
					searchable: false
				}, {
					dataField: 'company',
					text: 'Company',
					sort: true,
					searchable: false
				}, {
					dataField: 'userType',
					text: 'Type',
					sort: true,
					searchable: false
				}, {
					dataField: 'credits',
					text: 'Balance(Rs)',
					sort: false,
					searchable: false
				},{
					dataField: 'planName',
					text: 'Voice Plan',
					sort: false,
					searchable: false
				},{
					dataField: 'parentUser',
					text: 'Parent',
					sort: false,
					searchable: false
				},{
					dataField: 'expiryDate',
					text: 'User Expiry',
					sort: false,
					searchable: false
				},{
					dataField: 'action',
					text: 'Action',
					sort: false,
					searchable: false
				},];
		}
		const users = Array.isArray(userList) ? userList.map((user, index) => {
			if (this.props.app.role === 'ROLE_SADMIN') {
				let action = [];
				let usercli = [];
				usercli.push(<Button key={`${index}-usercli`} className="btn-sm" onClick={(e) => { this.showCLIModal(user.userId) }} variant="primary">CLI List</Button>)
				action.push(<Dropdown key={`${index}-dropdown`}>
					<Dropdown.Toggle className="btn-sm mb-2" variant="primary">Take Action</Dropdown.Toggle>
						<Dropdown.Menu>
					  		<Dropdown.Item key={`${index}-edit-user`} href={`${this.props.app.urlPrefix}/update-user/${user.userId}`}>Edit User</Dropdown.Item>
					  		<Dropdown.Item key={`${index}-add-credit`} onClick={(e) => { this.showAddCreditModal(user.userId) }} href="#">Add Recharge</Dropdown.Item>
							<Dropdown.Item key={`${index}-remove-credit`} onClick={(e) => { this.showRemoveCreditModal(user.userId) }} href="#">Remove Recharge</Dropdown.Item>
					  		<Dropdown.Item key={`${index}-reset-pwd`} onClick={(e) => { this.showPwdModal(user.userId) }} href="#">Reset Password</Dropdown.Item>
					  		<Dropdown.Item key={`${index}-delete-user`} onClick={(e) => { this.confirmDelete(true, user.userId) }} href="#">Delete User</Dropdown.Item>
							<Dropdown.Item key={`${index}-allocate-cli`} onClick={(e) => { this.showAllocateCLIModal(user.userId) }} href="#">Allocate CLI</Dropdown.Item>
						</Dropdown.Menu>
				  	</Dropdown>);
				/*let action = [<Link id="edit-btn" data-tooltip-content="Edit User Profile" key={`${index}-edit-user`} className="edit-user" to={`${this.props.app.urlPrefix}/update-user/${user.userId}`}><i className="fa fa-edit"></i></Link>];
				action.push(<a id="add-btn" data-tooltip-content="Add Credits" key={`${index}-add-credit`} className="add-credit" onClick={(e) => { this.showAddCreditModal(user.userId) }} href="#"><i className="fa fa-plus"></i></a>)
				action.push(<a data-tooltip-content="Reset Password" key={`${index}-reset-pwd`} className="reset-pwd" onClick={(e) => { this.showPwdModal(user.userId) }} href="#"><i className="fa fa-refresh"></i></a>)
				action.push(<a data-tooltip-content="Delete User" key={`${index}-delete-user`} className="delete-user" onClick={(e) => { this.confirmDelete(true, user.userId) }} href="#"><i className="fa fa-trash"></i></a>)*/
				return {
					userId: user.userId,
					username: user.username,
					name: user.name,
					emailId: user.emailid,
					number: user.number,
					company: user.company,
					userType: user.userType,
					credits: user.credits,
					planName: user.planName,
					parentUser: user.parentUser,
					expiryDate: user.expiryDate,
					usercli: usercli,
					action: action,
				};
			}
			else {
				let action = [];
				action.push(<Dropdown>
					<Dropdown.Toggle className='btn-sm'>Take Action</Dropdown.Toggle>
						<Dropdown.Menu>
						<Dropdown.Item key={`${index}-edit-user`} href={`${this.props.app.urlPrefix}/update-user/${user.userId}`}>Edit User</Dropdown.Item>
					  		<Dropdown.Item key={`${index}-add-credit`} onClick={(e) => { this.showAddCreditModal(user.userId) }} href="#">Add Recharge</Dropdown.Item>
							<Dropdown.Item key={`${index}-add-credit`} onClick={(e) => { this.showRemoveCreditModal(user.userId) }} href="#">Remove Recharge</Dropdown.Item>
					  		<Dropdown.Item key={`${index}-reset-pwd`} onClick={(e) => { this.showPwdModal(user.userId) }} href="#">Reset Password</Dropdown.Item>
					  		<Dropdown.Item key={`${index}-delete-user`} onClick={(e) => { this.confirmDelete(true, user.userId) }} href="#">Delete User</Dropdown.Item>
						</Dropdown.Menu>
				  	</Dropdown>);
				/*let action = [<Link data-tip="Edit User Profile" key={`${index}-edit-user`} className="edit-user" to={`${this.props.app.urlPrefix}/update-user/${user.userId}`}><i className="fa fa-edit"></i></Link>];
				action.push(<a data-tip="Add Credits" key={`${index}-add-credit`} className="add-credit" onClick={(e) => { this.showAddCreditModal(user.userId) }} href="#"><i className="fa fa-plus"></i></a>)
				action.push(<a data-tip="Reset Password" key={`${index}-reset-pwd`} className="reset-pwd" onClick={(e) => { this.showPwdModal(user.userId) }} href="#"><i className="fa fa-refresh"></i></a>)
				action.push(<a data-tip="Delete User" key={`${index}-delete-user`} className="delete-user" onClick={(e) => { this.confirmDelete(true, user.userId) }} href="#"><i className="fa fa-trash"></i></a>)*/
				return {
					userId: user.userId,
					username: user.username,
					name: user.name,
					emailId: user.emailid,
					number: user.number,
					company: user.company,
					userType: user.userType,
					credits: user.credits,
					planName: user.planName,
					parentUser: user.parentUser,
					expiryDate: user.expiryDate,
					action: action
				};
			}
		}) : []
		const { SearchBar, ClearSearchButton } = Search;
		const { ExportCSVButton } = CSVExport;

		const defaultSorted = [{
			dataField: 'userId',
			order: 'desc'
		}];

		const paginationOption = {
			custom: true,
			totalSize: users.length,
			sizePerPage: 10,
			sizePerPageList: [10, 25, 50, 100]
		};

		return (
			<Card>
				<Card.Header>Users List</Card.Header>
				{/*<Card.Header>Users List<span>&nbsp;&nbsp;&nbsp;</span><span className="float-none avail-credit-cont"><span className="credits-avail">Available Credits: {this.state.adminCredits}</span></span></Card.Header>*/}

				<Card.Body>
					<div className="user-list-table-cont">
						{<PaginationProvider pagination={paginationFactory(paginationOption)}>
							{
								({
									paginationProps,
									paginationTableProps
								}) => (
									<ToolkitProvider
										keyField="userId"
										data={users}
										columns={columns}
										search
										bootstrap4
										exportCSV={ {
											fileName: 'users.csv'
										  } }
									>
										{
											props => (
												<div>
													Show <SizePerPageDropdownStandalone className="search-dropdown" {...paginationProps} /> items
													<span className="search-cont"><SearchBar  {...props.searchProps} /></span>
													<ClearSearchButton className="btn-primary btn-round" {...props.searchProps} />
													<ExportCSVButton className="export-csv btn-primary ml-2" {...props.csvProps}>Export to CSV</ExportCSVButton>
													{/*<span>&nbsp;&nbsp;&nbsp;</span><span className="float-none avail-credit-cont"><span className="credits-avail">Available Credits: {this.state.adminCredits}</span></span>*/}
													<Link className="float-right btn btn-default btn-primary" to={`${this.props.app.urlPrefix}/create-user`}>Add User</Link>
													<hr />
													<BootstrapTable defaultSorted={defaultSorted} hover bordered={true} wrapperClasses="table-responsive" classes="userlist-table"  {...props.baseProps}  {...paginationTableProps} />
													<PaginationListStandalone {...paginationProps} />
												</div>
											)
										}
									</ToolkitProvider>
								)
							}
						</PaginationProvider>}
					</div>
					<Modal size="md" show={this.state.showAddCreditModal} onHide={() => this.setMessageShow(false)}>
						<Modal.Header closeButton>
							<Modal.Title id="example-modal-sizes-title-sm">
								Add Recharge Amount
							</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<Form method="post" onSubmit={this.addCredits}>
								<Row>
									<Col sm={12}>
										<Form.Group>
											<Form.Control required name="creditsToAdd" type="number" placeholder="Recharge Amount(Rs)" onChange={e => this.setvalue(e)} value={this.state.creditsToAdd} />
										</Form.Group>
									</Col>
								</Row>
								<Button variant="primary" type="submit">Add Recharge</Button>
							</Form>
						</Modal.Body>
					</Modal>

					<Modal size="md" show={this.state.showRemoveCreditModal} onHide={() => this.setMessageShow(false)}>
						<Modal.Header closeButton>
							<Modal.Title id="example-modal-sizes-title-sm">
								Remove Recharge Amount
							</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<Form method="post" onSubmit={this.removeCredits}>
								<Row>
									<Col sm={12}>
										<Form.Group>
											<Form.Control required name="creditsToRemove" type="number" placeholder="Recharge Amount(Rs)" onChange={e => this.setvalue(e)} value={this.state.creditsToRemove} />
										</Form.Group>
									</Col>
								</Row>
								<Button variant="primary" type="submit">Remove Recharge</Button>
							</Form>
						</Modal.Body>
					</Modal>

					<Modal size="md" show={this.state.showPwdModal} onHide={() => this.setMessageShow(false)}>
						<Modal.Header closeButton>
							<Modal.Title id="example-modal-sizes-title-sm">
								Reset Password
							</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<Form method="post" onSubmit={this.resetPassword}>
								<Row>
									<Col sm={6}>
										<Form.Group>
											<Form.Control required name="password" type="password" placeholder="Password" onChange={e => this.setvalue(e)} value={this.state.password} />
										</Form.Group>
									</Col>
								</Row>
								<Row>
									<Col sm={6}>
										<Form.Group>
											<Form.Control required name="confirmPassword" type="password" placeholder="Confirm Password" onChange={e => this.setvalue(e)} value={this.state.confirmPassword} />
										</Form.Group>
									</Col>
								</Row>
								<Button variant="primary" type="submit">Reset Password</Button>
							</Form>
						</Modal.Body>
					</Modal>


					<Modal size="sm" show={this.state.showModal} onHide={() => this.setMessageShow(false)}>
						<Modal.Header closeButton>
							<Modal.Title id="example-modal-sizes-title-sm">
								{this.state.modalHeading}
							</Modal.Title>
						</Modal.Header>
						<Modal.Body>{this.state.modalContent}</Modal.Body>
					</Modal>

					<Modal size="md" show={this.state.confirmDelete} onHide={() => this.confirmDelete(false, '')}>
						<Modal.Header closeButton>
							<Modal.Title id="example-modal-sizes-title-sm">
								Delete User
							</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							Are you sure you want to delete this user?
						</Modal.Body>
						<Modal.Footer>
							<Button onClick={() => this.confirmDelete(false, '')} variant="secondary">CANCEL</Button>
							<Button onClick={this.deleteUser} variant="primary">YES</Button>
						</Modal.Footer>
					</Modal>
					<Modal size="md" show={this.state.confirmRemoveCLI} onHide={() => this.confirmRemoveCLI(false, '')}>
						<Modal.Header closeButton>
							<Modal.Title id="example-modal-sizes-title-sm">
								Remove CLI
							</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							Are you sure you want to remove this CLI?
						</Modal.Body>
						<Modal.Footer>
							<Button onClick={() => this.confirmRemoveCLI(false, '')} variant="secondary">CANCEL</Button>
							<Button onClick={this.removeCLI} variant="primary">YES</Button>
						</Modal.Footer>
					</Modal>
					<Modal size="md" show={this.state.showAllocateCLIModal} onHide={() => this.setMessageShow(false)}>
						<Modal.Header closeButton>
							<Modal.Title id="example-modal-sizes-title-sm">
								Allocate CLI
							</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<Form method="post" onSubmit={this.allocateCLI}>
								<Row>
									<Col sm={8}>
										<Form.Group>
											<Form.Select required name="cliId" onChange={e => this.setvalue(e)} as="select">
												<option value="">Select CLI</option>
												{availableCliDropdown}
											</Form.Select>
										</Form.Group>
									</Col>
									<Col>
											<Button className="btn-sm" onClick={this.handleAddRow} variant="primary">Add CLI</Button>
									</Col>
								</Row>
								<Row>
									<Col sm={8}>
										<div className="react-bootstrap-table">

											<table className="table table-striped table-bordered table-sm widgetlist-table">
												<thead>
													<tr>
														<th>CLI</th>
														<th>Action</th>
													</tr>
												</thead>
												<tbody>
													{cliTable}
												</tbody>
											</table>
										</div>
									</Col>
								</Row>
								<Button variant="primary" type="submit">Allocate CLI</Button>
							</Form>
						</Modal.Body>
					</Modal>
					<Modal size="md" show={this.state.showCLIModal} onHide={() => this.setMessageShow(false)}>
						<Modal.Header closeButton>
							<Modal.Title id="example-modal-sizes-title-sm">
								CLI List
							</Modal.Title>
						</Modal.Header>
						<Modal.Body>
								<Row>
									<Col sm={8}>
										<div className="react-bootstrap-table">

											<table className="table table-striped table-bordered table-sm widgetlist-table">
												<thead>
													<tr>
														<th>CLI</th>
														<th>Action</th>
													</tr>
												</thead>
												<tbody>
													{allocatedCliTable}
												</tbody>
											</table>
										</div>
									</Col>
								</Row>
						</Modal.Body>
					</Modal>

				</Card.Body>
			</Card>

		);
	}
}

export default UserManagement;