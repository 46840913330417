import React, { Component } from 'react';
import { Button, Card, Col, Form, Row, Modal } from 'react-bootstrap';
import { cloneDeep, isEmpty, isNull } from 'lodash';
import { Fetcher } from "../../../Helpers/fetcher.js";
import { Redirect } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import "../index.css";
class CreateUser extends Component {
	constructor(props) {
		super(props);
		this.logoInput = React.createRef();
		this.state = {
			username: '',
			password: '',
			planId: '',
			confirmPassword: '',
			name: '',
			emailid: '',
			number: '',
			address: '',
			company: '',
			pincode: '',
			credits: '',
			domain: '',
			channels: 0,
			parentChannels: 0,
			userChannels: 0,
			logoInput: this.logoInput,
			logo: '',
			redirectFlag: false,
			showModal: false,
			modalHeading: 'Error',
			modalContent: '',
			userType: '',
			planList: [],
			accountType: '',
			parentAccount: '',
			userId: props.app.userId,
			apiPath: props.app.apiPath,
			channelsAvailable: '',
			sendDate: new Date(),
			logoDomain: false,
		};

	}
	componentDidMount() {
		const apiPath = this.state.apiPath;
		const userId = this.state.userId;
		const parent = this.props.app.userId;
		Fetcher.get(apiPath + '/api/obd/user/profile/' + userId)
			.then(res => {
				this.setState({
					parentChannels: res.channels,
					parentAccount: res.accountType
				})
			})
			.catch(err => { console.log('Error in fetching Users data', err) });

		Fetcher.get(apiPath + '/api/obd/user/channels/' + userId)
			.then(res => {
				this.setState({
					userChannels: res.userChannels
				})
			})
			.catch(err => { console.log('Error in fetching Users data', err) });

		// Get Plan List
		Fetcher.get(apiPath + '/api/obd/plans/list/' + userId)
			.then(res => {
				this.setState({
					planList: res
				})
			})
			.catch(err => { console.log('Error in fetching Plans', err) });
	}

	sendLaterDateChange = date => {
		this.setState({
			sendDate: date
		});
	};
	
	setvalue = (e) => {
		this.setState({
			[e.target.name]: e.target.value,
		});
	}
	addZero = (i) => {
		if (i < 10) {
			i = "0" + i;
		}
		return i;
	}
	toggleChange = (e, name) => {
		this.setState({
			[name]: !this.state[name],
		});
	}
	hideModal = () => {
		this.setState({
			showModal: false,
			redirectFlag: this.state.redirectFlag
		});
	}


	registerUser = (event) => {
		event.preventDefault();
		const state = cloneDeep(this.state);
		if (state.confirmPassword !== state.password) {
			alert('Password & Confim Password don\'t match');
			return;
		}
		if (state.password.length < 8) {
			alert('Password cannot be less than 8 characters');
			return;
		}
		if(state.channels > state.parentChannels - state.userChannels)
		{
			alert('Channel Limit Exceeded');
			return;
		}
		const date = state.sendDate;
		let sendDateTime = this.addZero(date.getFullYear());
		sendDateTime += "-";
		sendDateTime += this.addZero((date.getMonth() + 1));
		sendDateTime += "-"
		sendDateTime += this.addZero(date.getDate());
		sendDateTime += " 23:59:59"
		/*sendDateTime += this.addZero(date.getHours());
		sendDateTime += ":"
		sendDateTime += this.addZero(date.getMinutes());
		sendDateTime += ":"
		sendDateTime += this.addZero(date.getSeconds());*/
		
		var logo = null;
		var fd = new FormData();
		
		if(this.state.logoDomain)
		{
			logo = state.logoInput.current.files[0];
			fd.append('username', state.username);
			fd.append('password', state.password);
			fd.append('planId', state.planId);
			fd.append('name', state.name);
			fd.append('emailid', state.emailid);
			fd.append('number', state.number);
			fd.append('address', state.address);
			fd.append('company', state.company);
			fd.append('pincode', state.pincode);
			fd.append('logo', logo);
			fd.append('domain', state.domain);
			fd.append('parent', this.props.app.userId);
			fd.append('accountType', state.accountType);
			fd.append('userType', state.userType);
			fd.append('channels', 30);
			fd.append('expiryDate', sendDateTime);
			this.saveDataWithLogo(fd);
		}
		else
		{
			const data = {
				username: state.username,
				password: state.password,
				planId: state.planId,
				name: state.name,
				emailid: state.emailid,
				number: state.number,
				address: state.address,
				company: state.company,
				pincode: state.pincode,
				parent: this.props.app.userId,
				accountType: state.accountType,
				userType: state.userType,
				channels: 30,
				expiryDate: sendDateTime
			}
			this.saveData(data);
		}
	}
	saveDataWithLogo = (fd) => {
		return Fetcher.post(this.props.app.apiPath + '/api/obd/register1', { method: 'POST', body: fd })
			.then(res => {
				let modalHeading;
				let redirectFlag;
				if(res.message === 'User Created Successfully')
				{
					modalHeading = 'Success';
					redirectFlag = true;
				}
				else
				{
					modalHeading = 'Error';
					redirectFlag = false;
				}
				
				this.setState({
					showModal: true,
					modalHeading: modalHeading,
					modalContent: res.message,
					redirectFlag: redirectFlag
				})

			})
			.catch(err => {
				console.log('Error in Registering Account', err)
				this.setState({
					showModal: true,
					modalHeading: 'Error',
					modalContent: err.message,

				})
			});

	}
	saveData = (data) => {
		return Fetcher.post(this.props.app.apiPath + '/api/obd/register', { headers: { "Content-type": "application/json" }, method: 'POST', body: JSON.stringify(data) })
			.then(res => {
				let modalHeading;
				let redirectFlag;
				alert(res.message);
				if(res.message === 'User Created Successfully')
				{
					modalHeading = 'Success';
					redirectFlag = true;
				}
				else
				{
					modalHeading = 'Error';
					redirectFlag = false;
				}
				this.setState({
					showModal: false,
					modalHeading: modalHeading,
					modalContent: res.message,
					redirectFlag: redirectFlag
				})

			})
			.catch(err => {
				console.log('Error in Registering Account', err)
				this.setState({
					showModal: true,
					modalHeading: 'Error',
					modalContent: err.message,

				})
			});

	}

	setMessageShow = (status) => {
		this.setState({
			showModal: false,
		});
	}

	render() {
		if (this.state.redirectFlag === true) {
			return <Redirect to={'/user-list/'} />
		}
		const planList = cloneDeep(this.state.planList);
		let planDropdown = '';
		if (!isEmpty(planList) && (!('error' in planList))) {
			planDropdown = Array.isArray(planList) ? planList.map((obj, index) => {
				return <option value={obj.planId} key={`planid${index}`} >{obj.planName}</option>
			}) : []

		} else {
			planDropdown = <option value="" >No Plan found</option>
		}
		const filterTime = (date) => {
			const isPastTime = new Date().getTime() > date.getTime();
			return !isPastTime;
			};
			const sendLater = this.state.sendLater;
			let sendLaterDatepicker = '';
			sendLaterDatepicker = 
			<Col sm={3}>
				<Form.Group className="form-group expiry required control-label">
					<Form.Label>User Expiry</Form.Label>
					<DatePicker
						className="form-control"
						selected={this.state.sendDate}
						minDate={new Date()}
						timeCaption="Time"
						dateFormat="yyyy-MM-dd"
						onChange={this.sendLaterDateChange}
						filterTime={filterTime}
					/>
				</Form.Group>
			</Col>
			
		return (
			<Form method="post" onSubmit={this.registerUser}>
				<Row>
					<Col sm={12}>
						<Card>
							<Card.Header>Create New User</Card.Header>
							<Card.Body>
								<Row>
									<Col sm={3}>
										<Form.Group className="form-group required control-label">
											<Form.Control required name="username" type="text" placeholder="Username" onChange={e => this.setvalue(e)} value={this.state.username} autoComplete="off" />
										</Form.Group>
									</Col>
									<Col sm={3}>
										<Form.Group className="form-group required control-label">
											<Form.Control required name="password" type="password" placeholder="Password" onChange={e => this.setvalue(e)} value={this.state.password} autoComplete="new-password"/>
										</Form.Group>
									</Col>
									<Col sm={3}>
										<Form.Group className="form-group required control-label">
											<Form.Control required name="confirmPassword" type="password" placeholder="Confirm Password" onChange={e => this.setvalue(e)} value={this.state.confirmPassword} />
										</Form.Group>
									</Col>
								</Row>
								
								<Row>
									<Col sm={3}>
										<Form.Group className="form-group required control-label">
											<Form.Control required name="name" type="text" placeholder="Name" onChange={e => this.setvalue(e)} value={this.state.name} />
										</Form.Group>
									</Col>
									<Col sm={3}>
										<Form.Group className="form-group required control-label">
											<Form.Control required name="emailid" type="email" placeholder="Email ID" onChange={e => this.setvalue(e)} value={this.state.emailid} />
										</Form.Group>
									</Col>
									<Col sm={3}>
										<Form.Group className="form-group required control-label">
											<Form.Control required name="number" type="number" placeholder="Mobile Number" onChange={e => this.setvalue(e)} value={this.state.number} />
										</Form.Group>
									</Col>
								</Row>
								<Row>
									<Col sm={3}>
										<Form.Group className="form-group required control-label">
											<Form.Control required name="company" type="text" placeholder="Company Name" onChange={e => this.setvalue(e)} value={this.state.company} />
										</Form.Group>
									</Col>
									<Col sm={3}>
										<Form.Group className="form-group required control-label">
											<Form.Control required name="address" type="text" placeholder="Address" onChange={e => this.setvalue(e)} value={this.state.address} />
										</Form.Group>
									</Col>
									<Col sm={3}>
										<Form.Group className="form-group required control-label">
											<Form.Control required name="pincode" type="number" placeholder="Pincode" onChange={e => this.setvalue(e)} value={this.state.pincode} />
										</Form.Group>
									</Col>
								</Row>
								<Row>
									<Col sm={3}>
										<Form.Group className="form-group required control-label">
											<Form.Select className="dd" required name="userType" onChange={e => this.setvalue(e)} as="select">
												<option value="">Select Customer Type</option>
												<option value="reseller">Reseller</option>
												<option value="user">User</option>
											</Form.Select>
										</Form.Group>
									</Col>
									{(this.props.app.role === 'ROLE_SADMIN') && <Col sm={3}>
										<Form.Group className="form-group required control-label">
											<Form.Select className="dd" required name="accountType" value={this.state.accountType} onChange={e => this.setvalue(e)} as="select">
												<option value="">Select Account Type</option>
												<option value="0">Promotional</option>
												<option value="1">Transactional</option>
											</Form.Select>
										</Form.Group>
									</Col>}
									{(this.props.app.role === 'ROLE_ADMIN') && <Col sm={3}>
										<Form.Group className="form-group required control-label">
											<Form.Select className="dd" required name="accountType" value={this.state.accountType} onChange={e => this.setvalue(e)} as="select">
												<option value="">Select Account Type</option>
												{(this.state.parentAccount === 0) && <option value="0">Promotional</option>}
												{(this.state.parentAccount === 1) && <option value="1">Transactional</option>}
											</Form.Select>
										</Form.Group>
									</Col>}
									<Col sm={3}>
										<Form.Group className="form-group required control-label">
											<Form.Select className="dd" required name="planId" onChange={e => this.setvalue(e)} as="select">
												<option value="">Select Voice Plan</option>
												{planDropdown}
											</Form.Select>
										</Form.Group>
									</Col>
								</Row>
								<Row>
									<Col sm={3}>
										<Form.Group controlId="Send-Logo">
											<Form.Check checked={this.state.logoDomain} onChange={( e ) => this.toggleChange( e, 'logoDomain' )} custom inline name="logoDomain" label="Send Logo and Domain" id="logoDomian" type="checkbox" />
										</Form.Group>
									</Col>
								</Row>
								{(this.state.logoDomain) && <Row>
									<Col sm={3}>
										<Form.Group >
											<Form.Label>Logo</Form.Label>
											<Form.Control name="logo" onChange={this.onFileChangeHandler} ref={this.logoInput} type="file" />
										</Form.Group>
									</Col>
									<Col sm={3}>
										<Form.Group>
										<Form.Label>Domain</Form.Label>
											<Form.Control name="domain" type="text" placeholder="Domain" onChange={e => this.setvalue( e )} value={this.state.domain} />
										</Form.Group>
									</Col>
								</Row>}
								
								<Modal size="sm" show={this.state.showModal} onHide={() => this.setMessageShow(false)}>
									<Modal.Header closeButton>
										<Modal.Title id="example-modal-sizes-title-sm">
											{this.state.modalHeading}
										</Modal.Title>
									</Modal.Header>
									<Modal.Body>{this.state.modalContent}</Modal.Body>
								</Modal>
								<Button className="btn-round" variant="primary" type="submit">Create User</Button>
							</Card.Body>
						</Card>
					</Col>
				</Row>
			</Form>
		);
	}

}
export default CreateUser;