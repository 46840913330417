import React, { Component } from 'react';
import { Button, Card, Col, Container, Form, InputGroup, Row } from 'react-bootstrap';
import { Fetcher } from '../../Helpers/fetcher.js';

import "./index.css";
class UpdatePwd extends Component {
	constructor(props) {
		super(props);
		this.state = {
			password: '',
			confirmPassword: '',
			apiPath: this.props.app.apiPath,
			token: this.props.selectedToken,
			showModal: false,
			modalHeading: 'Status',
			modalContent: ''
		}
	}

	setvalue = (e) => {
		this.setState({
			[e.target.name]: e.target.value,
		});
	}

	updatePwd = (e) => {
		e.preventDefault();
		const password = this.state.password;
		const token = this.state.token;
		const confirmPassword = this.state.confirmPassword;
		if (this.state.confirmPassword !== this.state.password) {
			alert('Password & Confim Password don\'t match');
			return;
		}

		else if (password === '') {
			alert('Please enter passowrd');
			this.setState({
				showModal: true,
				modalHeading: 'Error',
				modalContent: 'Please enter Password'
			})
		} 
		else if (confirmPassword === '') {
			this.setState({
				showModal: true,
				modalHeading: 'Error',
				modalContent: 'Please enter Confirm Password'
			})
		}
		else {
			const data = {
				password: password,
				token: token
			}
			return Fetcher.post(this.props.app.apiPath + '/api/obd/update/pwd', { headers: { "Content-type": "application/json" }, method: 'POST', body: JSON.stringify(data) })
				.then(res => {

					console.log(res);
					let modalHeading;
					if (res.message === 'Parameters Incorrect') {
						alert('Password Reset Failed');
						modalHeading = 'Error';
						this.setState({
							showModal: true,
							modalHeading: modalHeading,
							modalContent: 'Parameters Incorrect'
						})
					} else if(res.message === 'Password Reset Successfully'){
						alert(res.message);
						window.location.href='/login';
					}
					else {
						console.log(res.message);
						alert('Password Reset Failed');
						this.setState({
							showModal: true,
							modalHeading: 'Error',
							modalContent: res.message
						})
					}
				})
				.catch(err => {
					console.log('Error in UpdatePwd', err)
					this.setState({
						showModal: true,
						modalHeading: 'Error',
						modalContent: err.message
					})
				});

		}
	}

	setMessageShow = (status) => {
		this.setState({
			showModal: status,
		});
	}


	render() {
		return (
			<div className="app flex-row align-items-center">
				<Container>
					<Row className="justify-content-center">
						<Col md="4">
							<Card className="p-4">
								<Card.Body>
									<Form method="post" onSubmit={this.updatePwd}>
										<div className="UpdatePwd-logo text-center">
											{this.props.app.logo && <img className="navbar-brand-full" src={this.props.app.logo} alt="CoreUI Logo" />}
										</div>

										<p className="text-muted text-center">Update Password</p>
										<InputGroup className="mb-3">
											<Form.Control className="field-password" name="password" type="password" placeholder="Password" onChange={e => this.setvalue(e)} value={this.state.password} autoComplete="username" />
										</InputGroup>

										<InputGroup className="mb-3">
											
											<Form.Control className="field-password" name="confirmPassword" type="password" placeholder="Confirm Password" onChange={e => this.setvalue(e)} value={this.state.confirmPassword} autoComplete="username" />
										</InputGroup>
										
										<Row>
											<Col xs="4">
												<Button type="submit" color="primary" className="px-4 btn btn-primary btn-round">Update</Button>
											</Col>
										</Row>
									</Form>
								</Card.Body>
							</Card>
						</Col>
					</Row>
				</Container>

			</div>
		);
	}
}

export default UpdatePwd;