import React, { Component } from 'react';
import { Form, Row, Col, Card, Button, Modal, Tabs, Tab } from 'react-bootstrap';
import { Redirect } from "react-router-dom";
import DatePicker from "react-datepicker";
import { Fetcher } from "../../../Helpers/fetcher.js";
import { cloneDeep, isEmpty } from 'lodash';
import "react-datepicker/dist/react-datepicker.css";
import "./index.css";

class AddVoiceCampaign extends Component {
	constructor(props) {
		super(props);

		this.baseFileInput = React.createRef();
		let sitePath = '';
		let filePath = '';

		const protocol = window.location.protocol;
		const slashes = protocol.concat("//");
		sitePath = slashes.concat(window.location.hostname);

		this.state = {
			campaignName: '',
			baseName: '',
			patchList: '',
			prompts: '',
			templateId: '0',
			dtmf: '',
			agentNumber: '',
			agentDtmf: '',
			baseFileType: 'contactList',
			sendLater: false,
			sendDate: new Date(),
			baseFileInput: this.baseFileInput,
			userId: props.app.userId,
			apiPath: props.app.apiPath,
			sitePath: sitePath,
			filePath: filePath,
			contactList: '',
			oldBaseId: '',
			promptId: '',
			welcomeId: '',
			menu1: '',
			menu2: '',
			menu3: '',
			menu4: '',
			menu5: '',
			menuId: '',
			noinputId: '',
			wronginputId: '',
			thanksId: '',
			optin1: '',
			optin2: '',
			optout1: '',
			optout2: '',
			action1: '',
			action2: '',
			date1: '',
			date2: '',
			date3: '',
			senderId: '',
			baseUploads: [],
			promptUploads: [],
			senderIdList: [],
			showModal: false,
			modalHeading: 'Status',
			modalContent: '',
			redirectToSummary: false,
			campaignCreated: false,
			sendSmsSuccess: false,
			sendSmsFail: false,
			sendSmsDtmf: false,
			prompt: true,
			welcomePrompt: false,
			menuPrompt: true,
			noinputPrompt: false,
			wronginputPrompt: false,
			thanksPrompt: false,
			sendLink: false,
			smsLink: '',
			smsApiSuccess: '',
			smsApiFail: '',
			smsApiDtmf: '',
			callDurationSMS: 0,
			trunk: '',
			channels: '',
			agentRows: [],
			channelsList: [],
			menuWaitTime: '',
			rePrompt: '',
			cli: '',
			sectionArray1: [],
			sectionArray2: [],
			sectionArray3: [],
			sectionArray4: [],
			sectionArray5: [],
			section1: '',
			section2: '',
			section3: '',
			section4: '',
			section5: '',
			promptArray: [],
			templateName: '',
			token1: ''
		}

	}

	componentDidMount() {
		const userId = this.state.userId;
		const apiPath = this.state.apiPath;

		// Get BaseUpload Files List
		Fetcher.get(apiPath + '/api/obd/base/' + userId)
			.then(res => {
				this.setState({
					baseUploads: res
				})
			})
			.catch(err => { console.log('Error in fetching BaseUpload Files', err) });

		// Get PromptUpload Files List
		Fetcher.get(apiPath + '/api/obd/prompts/' + userId)
			.then(res => {
				this.setState({
					promptUploads: res
				})
			})
			.catch(err => { console.log('Error in fetching PromptUpload Files', err) });

		// Get Sender Id List
		Fetcher.get(apiPath + '/api/obd/cli/user/' + userId)
			.then(res => {
				this.setState({
					senderIdList: res
				})
			})
			.catch(err => { console.log('Error in fetching Sender Id\'s', err) });

		// Get Channels
		/*Fetcher.get(apiPath + '/api/obd/channels/' + userId)
			.then(res => {
				var slots = res.channels / 20;
				var channelList = [];
				for (let i = 1; i <= slots; i++) {
					const item = {
						slot: i * 20
					};
					channelList.push(item);
				}
				this.setState({
					channelsList: channelList
				})
			})
			.catch(err => { console.log('Error in fetching Channels', err) });*/
	}

	sendLaterDateChange = date => {
		this.setState({
			sendDate: date
		});
	};
	toggleChange = (e, name) => {
		this.setState({
			[name]: !this.state[name],
		});
	}

	handleSelect = function (selectedItems) {
		const dtmfSelected = [];
		for (let i = 0; i < selectedItems.length; i++) {
			dtmfSelected.push(selectedItems[i].value);
		}
		this.setState({
			dtmf: dtmfSelected.toString()
		});
	}

	handleChange = idx => e => {
		const { name, value } = e.target;
		const agentRows = [...this.state.agentRows];
		agentRows[idx] = {
			[name]: value
		};
		this.setState({
			agentRows
		});
	};
	handleAddRow = () => {
		if (this.state.agentNumber !== '' && this.state.agentDtmf !== '') {
			const item = {
				agentNumber: this.state.agentNumber,
				agentDtmf: this.state.agentDtmf
			};
			const checkDtmf = obj => obj.agentDtmf === this.state.agentDtmf;
			if(this.state.agentRows.some(checkDtmf))
			{
					alert(this.state.agentDtmf+ ' DTMF is already selected');
					return;
			}
			this.state.agentRows.push(item);
			this.setState({
				agentRows: this.state.agentRows
			});
			this.state.patchList = {
				"patchList": this.state.agentRows
			}
		}
		else {
			alert("Please select Agent and DTMF");
		}
	};
	handleRemoveRow = () => {
		this.setState({
			agentRows: this.state.agentRows.slice(0, -1)
		});
	};

	downloadCsv = (templateId,templateName) => {
		if(templateId === '4')
		{
			templateName = 'insurance1';
		}
		else if(templateId === '5')
		{
			templateName = 'service_reminder1';
		}
		window.open(this.props.app.reportPath + '/sample/sample_'+templateName+'.csv', 'Download');
	}

	setvalue = (e) => {
		this.setState({
			[e.target.name]: e.target.value,
		});
	}
	setTemplate = (e) => {
		this.setState({
			//campaignName: '',
			baseName: '',
			patchList: '',
			dtmf: '',
			agentNumber: '',
			agentDtmf: '',
			baseFileType: 'contactList',
			sendLater: false,
			sendDate: new Date(),
			baseFileInput: this.baseFileInput,
			contactList: '',
			oldBaseId: '',
			promptId: '',
			welcomeId: '',
			menu1: '',
			menu2: '',
			menu3: '',
			menu4: '',
			menu5: '',
			menuId: '',
			noinputId: '',
			wronginputId: '',
			thanksId: '',
			optin1: '',
			optin2: '',
			optout2: '',
			optout2: '',
			action1: '',
			action2: '',
			date1: '',
			date2: '',
			date3: '',
			senderId: '',
			showModal: false,
			modalHeading: 'Status',
			modalContent: '',
			redirectToSummary: false,
			campaignCreated: false,
			sendSmsSuccess: false,
			sendSmsFail: false,
			sendSmsDtmf: false,
			prompt: true,
			sendLink: false,
			smsLink: '',
			smsApiSuccess: '',
			smsApiFail: '',
			smsApiDtmf: '',
			callDurationSMS: 0,
			trunk: '',
			agentRows: [],
			templateId: e.target.value,
			welcomePrompt: false,
			menuPrompt: false,
			noinputPrompt: false,
			wronginputPrompt: false,
			thanksPrompt: false,
			menuWaitTime: '',
			rePrompt: '',
			cli: '',
			sectionArray1: [],
			sectionArray2: [],
			sectionArray3: [],
			sectionArray4: [],
			sectionArray5: [],
			section1: '',
			section2: '',
			section3: '',
			section4: '',
			section5: '',
			promptArray: [],
			token1: ''
		});
	}
	setUploadType = (type, uploadTypeKey) => {
		this.setState({
			[uploadTypeKey]: type,
		});
	}

	setMessageShow = (status) => {
		const campaignCreated = this.state.campaignCreated;
		this.setState({
			showModal: status,
			redirectToSummary: campaignCreated
		});
	}


	onFileChangeHandler = event => {
		//if ( this.checkMimeType( event ) && this.checkFileSize( event ) ) {
		if (this.checkMimeType(event)) {

		}
	}

	// Add Campaign
	handleAddCampaignSubmit = async (event) => {
		event.preventDefault();
		const formData = cloneDeep(this.state);
		console.log(formData);
		if (formData.agentRows.length === 0 && formData.templateId === '2') {
			alert('Please add Agent and DTMF Row');
			return;
		}
		const date = formData.sendDate;
		let sendDateTime = this.addZero(date.getFullYear());
		sendDateTime += "-";
		sendDateTime += this.addZero((date.getMonth() + 1));
		sendDateTime += "-"
		sendDateTime += this.addZero(date.getDate());
		sendDateTime += " "
		sendDateTime += this.addZero(date.getHours());
		sendDateTime += ":"
		sendDateTime += this.addZero(date.getMinutes());
		sendDateTime += ":"
		sendDateTime += this.addZero(date.getSeconds());
		await this.uploadBaseAndPromptFiles(formData)
			.then(([baseId]) => {
				if (this.state.patchList === '' && formData.templateId === '2') {
					alert("Please add Agent Row");
					return;
				}
				if(!this.state.sendSmsSuccess)
				{
					formData.smsApiSuccess='';
					formData.callDurationSMS=0;
				}
				if(!this.state.sendSmsFail)
				{
					formData.smsApiFail='';
				}
				if(!this.state.sendSmsDtmf)
				{
					formData.smsApiDtmf='';
				}
				if(formData.templateId === '3' && formData.templateName === 'auto1')
				{
					var obj1 = {
						type: "menu1",
						value: formData.menu1
					};
					var obj2 = {
						type: "date1",
						value: formData.date1
					};
					var obj3 = {
						type: "menu2",
						value: formData.menu2
					};

					this.state.sectionArray1.push(obj1);
					this.state.sectionArray1.push(obj2);
					this.state.sectionArray1.push(obj3);

					this.state.promptArray.push(this.state.sectionArray1);
					obj1 = {
						type: "optin1",
						value: formData.optin1
					};
					obj2 = {
						type: "date2",
						value: formData.date2
					};
					obj3 = {
						type: "optin2",
						value: formData.optin2
					};
					this.state.sectionArray2.push(obj1);
					this.state.sectionArray2.push(obj2);
					this.state.sectionArray2.push(obj3);
					this.state.promptArray.push(this.state.sectionArray2);

					this.setState({
						promptArray: this.state.promptArray
					});
					this.state.promptArray = {
						"section1": this.state.sectionArray1,
						"section2": this.state.sectionArray2
					}
					this.state.prompts = {
						"prompts": this.state.promptArray
					}
				}
				else if(formData.templateId === '3' && formData.templateName === 'auto2')
				{
					var obj1 = {
						type: "menu1",
						value: formData.menu1
					};
					var obj2 = {
						type: "token1",
						value: formData.token1
					};
					var obj3 = {
						type: "menu2",
						value: formData.menu2
					};

					this.state.sectionArray1.push(obj1);
					this.state.sectionArray1.push(obj2);
					this.state.sectionArray1.push(obj3);

					this.state.promptArray.push(this.state.sectionArray1);

					obj1 = {
						type: "optin1",
						value: formData.optin1
					};

					this.state.sectionArray2.push(obj1);
					this.state.promptArray.push(this.state.sectionArray2);

					this.setState({
						promptArray: this.state.promptArray
					});
					this.state.promptArray = {
						"section1": this.state.sectionArray1,
						"section2": this.state.sectionArray2
					}
					this.state.prompts = {
						"prompts": this.state.promptArray
					}
				}

				if(formData.templateId === '3' && formData.templateName === 'auto3')
				{
					var obj1 = {
						type: "menu1",
						value: formData.menu1
					};
					var obj2 = {
						type: "date1",
						value: formData.date1
					};
					var obj3 = {
						type: "menu2",
						value: formData.menu2
					};

					this.state.sectionArray1.push(obj1);
					this.state.sectionArray1.push(obj2);
					this.state.sectionArray1.push(obj3);

					this.state.promptArray.push(this.state.sectionArray1);
					obj1 = {
						type: "optin1",
						value: formData.optin1
					};
					this.state.sectionArray2.push(obj1);
					this.state.promptArray.push(this.state.sectionArray2);

					var obj1 = {
						type: "optout1",
						value: formData.optout1
					};
					var obj2 = {
						type: "date2",
						value: formData.date2
					};
					var obj3 = {
						type: "optout2",
						value: formData.optout2
					};

					this.state.sectionArray3.push(obj1);
					this.state.sectionArray3.push(obj2);
					this.state.sectionArray3.push(obj3);

					var obj1 = {
						type: "action1",
						value: formData.action1
					};
					var obj2 = {
						type: "date3",
						value: formData.date3
					};
					var obj3 = {
						type: "action2",
						value: formData.action2
					};

					this.state.sectionArray4.push(obj1);
					this.state.sectionArray4.push(obj2);
					this.state.sectionArray4.push(obj3);

					this.state.promptArray.push(this.state.sectionArray4);

					this.setState({
						promptArray: this.state.promptArray
					});
					this.state.promptArray = {
						"section1": this.state.sectionArray1,
						"section2": this.state.sectionArray2,
						"section3": this.state.sectionArray3,
						"section4": this.state.sectionArray4,
					}
					this.state.prompts = {
						"prompts": this.state.promptArray
					}
				}
				else if(formData.templateId === '4')
				{
					var obj1 = {
						type: "menu1",
						value: formData.menu1
					};
					var obj2 = {
						type: "amount1",
						value: formData.amount1
					};
					var obj3 = {
						type: "menu2",
						value: formData.menu2
					};
					var obj4 = {
						type: "date1",
						value: formData.date1
					};
					var obj5 = {
						type: "menu3",
						value: formData.menu3
					};

					this.state.sectionArray1.push(obj1);
					this.state.sectionArray1.push(obj2);
					this.state.sectionArray1.push(obj3);
					this.state.sectionArray1.push(obj4);
					this.state.sectionArray1.push(obj5);

					this.state.promptArray.push(this.state.sectionArray1);

					this.setState({
						promptArray: this.state.promptArray
					});
					this.state.promptArray = {
						"section1": this.state.sectionArray1
					}
					this.state.prompts = {
						"prompts": this.state.promptArray
					}
					formData.templateName = 'insurance1';
				}

				else if(formData.templateId === '5')
				{
					var obj1 = {
						type: "menu1",
						value: formData.menu1
					};
					var obj2 = {
						type: "company1",
						value: formData.company1
					};
					var obj3 = {
						type: "menu2",
						value: formData.menu2
					};
					var obj4 = {
						type: "service1",
						value: formData.service1
					};
					var obj5 = {
						type: "menu3",
						value: formData.menu3
					};
					var obj6 = {
						type: "amount1",
						value: formData.amount1
					};
					var obj7 = {
						type: "menu4",
						value: formData.menu4
					};
					var obj8 = {
						type: "month1",
						value: formData.month1
					};

					this.state.sectionArray1.push(obj1);
					this.state.sectionArray1.push(obj2);
					this.state.sectionArray1.push(obj3);
					this.state.sectionArray1.push(obj4);
					this.state.sectionArray1.push(obj5);
					this.state.sectionArray1.push(obj6);
					this.state.sectionArray1.push(obj7);
					this.state.sectionArray1.push(obj8);

					this.state.promptArray.push(this.state.sectionArray1);

					this.setState({
						promptArray: this.state.promptArray
					});
					this.state.promptArray = {
						"section1": this.state.sectionArray1
					}
					this.state.prompts = {
						"prompts": this.state.promptArray
					}
					formData.templateName = 'service_reminder1';
				}
				else if(formData.templateId === '6')
				{
					var menu1 = {
						prompt: formData.menu1,
						dtmf: "1|2|3"
					};
					var menu2 = {
						prompt: formData.menu2,
						dtmf: "1|2"
					};
					var menu3 = {
						prompt: formData.menu3,
						dtmf: "1|2|3"
					};
					var menu4 = {
						prompt: formData.menu4,
						dtmf: "1|2"
					};
					var menu5 = {
						prompt: formData.menu5,
						dtmf: "1|2"
					};
					
					this.state.prompts = {
						"menu1": menu1,
						"menu2": menu2,
						"menu3": menu3,
						"menu4": menu4,
						"menu5": menu5
					}
					formData.templateName = 'consultancy';
				}

				const data = {
					"userId": formData.userId,
					"campaignName": formData.campaignName,
					"templateId": formData.templateId,
					"dtmf": formData.dtmf,
					"baseId": baseId,
					"welcomePId": formData.welcomeId,
					"menuPId": formData.menuId,
					"noInputPId": formData.noinputId,
					"wrongInputPId": formData.wronginputId,
					"thanksPId": formData.thanksId,
					"scheduleTime": sendDateTime,
					"smsSuccessApi": formData.smsApiSuccess,
					"smsFailApi": formData.smsApiFail,
					"smsDtmfApi": formData.smsApiDtmf,
					"callDurationSMS": formData.callDurationSMS,
					"retries": 2,
					"retryInterval": 10,
					"agentRows": JSON.stringify(this.state.patchList),
					"prompts": JSON.stringify(this.state.prompts),
					"channels": 30,
					"menuWaitTime" : formData.menuWaitTime,
					"rePrompt": formData.rePrompt,
					"cli": formData.cli,
					"templateName": formData.templateName
				}
				this.addNewCampiagn(data);
			})

	}

	uploadBaseAndPromptFiles = async (formData) => {
		return new Promise(async (resolve, reject) => {

			var baseId = await this.maybeUploadBasefile(formData);

			resolve([baseId]);
			//reject();
		})
		//return Promise.all( [ this.maybeUploadBasefile( formData ), this.maybeUploadWelcomefile( formData ), this.maybeUploadMenufile( formData ), this.maybeUploadNoinputfile( formData ), this.maybeUploadWronginputfile( formData ), this.maybeUploadThanksfile( formData ) ] )
	}

	maybeUploadBasefile = async (formData) => {
		if (formData.baseFileType === 'oldBaseFile') {
			return formData.oldBaseId
		}
		else if (formData.baseFileType === 'contactList') {
			const fd = new FormData();
			fd.append('userId', formData.userId);
			fd.append('contactList', formData.contactList);
			//console.log(fd);
			return Fetcher.post(formData.apiPath + '/api/obd/contactlist', { method: 'POST', body: fd })
				.then(res => {
					return res.baseId;
				})
				.catch(err => { console.log('Error in uploading BaseUpload Files to Server', err) });
		}
		else {
			const fd = new FormData();
			fd.append('baseFile', formData.baseFileInput.current.files[0]);
			fd.append('baseName', formData.baseName);
			fd.append('userId', formData.userId);
			fd.append('contactList', '');

			return Fetcher.post(formData.apiPath + '/api/obd/baseupload', { method: 'POST', body: fd })
				.then(res => {
					return res.baseId;
				})
				.catch(err => { console.log('Error in uploading BaseUpload Files to Server', err) });
		}
	}

	addNewCampiagn = (data) => {
		console.log(data);
		return Fetcher.post(this.state.apiPath + '/api/obd/campaign/compose', { headers: { "Content-type": "application/json" }, method: 'POST', body: JSON.stringify(data) })
			.then(res => {
				let modalHeading;
				let campaignCreated = false;
				if (res.message === 'Parameters Incorrect') {
					modalHeading = 'Error';
				} else {
					modalHeading = 'Success';
					campaignCreated = true;
				}
				this.setState({
					showModal: true,
					modalHeading: modalHeading,
					modalContent: res.message,
					campaignCreated: campaignCreated
				})

			})
			.catch(err => {
				console.log('Error in Add Campaign Files', err)
				this.setState({
					showModal: true,
					modalHeading: 'Error',
					modalContent: err.message
				})
			});
	}


	addZero = (i) => {
		if (i < 10) {
			i = "0" + i;
		}
		return i;
	}

	getFileNameFromUrl = (url) => {
		var n = url.lastIndexOf('/');
		return url.substring(n + 1);
	}

	checkMimeType = (event) => {
		let files = event.target.files
		let allowedTypes = ['text/plain', 'text/csv'];
		if (event.target.name === 'newContactsFile') {
			allowedTypes = ['text/plain', 'text/csv'];
		} else {
			allowedTypes = ['audio/wav'];
		}
		let err = [] // create empty array

		for (var x = 0; x < files.length; x++) {
			if (allowedTypes.every(type => files[x].type !== type)) {
				err[x] = files[x].type + ' is not a supported format\n';
				// assign message to array
			}
		};
		for (var z = 0; z < err.length; z++) { // loop create toast massage
			event.target.value = null
			this.setState({
				showModal: true,
				modalHeading: 'Error',
				modalContent: err[z]
			})
			//toast.error( err[ z ] )
		}
		return true;
	}

	checkFileSize = (event) => {
		let allowedSize = 1;
		if (event.target.name === 'newContactsFile') {
			allowedSize = 20;
		}

		let files = event.target.files
		let err = [];
		for (var x = 0; x < files.length; x++) {
			if (((files[x].size / 1024 / 1024).toFixed(2)) > allowedSize) {
				err[x] = files[x].size + ' is too large, please pick a smaller file\n';
			}
		};
		for (var z = 0; z < err.length; z++) {
			//console.log( err )
			this.setState({
				showModal: true,
				modalHeading: 'Error',
				modalContent: err[z]
			})
			event.target.value = null
		}
		return true;
	}

	render() {
		// Redirect to Summary if Campaign Added successfully.
		if (this.state.redirectToSummary === true) {
			return <Redirect to='/voice-campaign-summary' />
		}

		const agentRows = cloneDeep(this.state.agentRows);
		const agentsTable = agentRows.map((item, index) => {
			return <tr key={index}>
				<td>{this.state.agentRows[index].agentNumber}</td>
				<td>{this.state.agentRows[index].agentDtmf}</td>
				<td>
					<i onClick={() => this.handleRemoveRow()} className="fa fa-minus-circle"></i>
				</td>
			</tr>;
		});

		//console.log( 'Add Campaign State', this.state );
		const baseUploads = cloneDeep(this.state.baseUploads);
		const promptUploads = cloneDeep(this.state.promptUploads);
		const senderIdList = cloneDeep(this.state.senderIdList);
		//const channelList = cloneDeep(this.state.channelsList);
		const sendLater = this.state.sendLater;
		let sendLaterDatepicker = '';
		let submitButtonlabel = 'Send Now';

		if (sendLater) {
			const filterTime = (date) => {
				const isPastTime = new Date().getTime() > date.getTime();
				return !isPastTime;
				};
			sendLaterDatepicker = <Row>
				<Col sm={10}>
					<Form.Group controlId="send-date">
						<Form.Label>Enter Date</Form.Label>
						<DatePicker
							className="form-control"
							showTimeSelect
							timeIntervals={15}
							minDate={new Date()}
							selected={this.state.sendDate}
							timeCaption="Time"
							dateFormat="yyyy-MM-dd hh:mm:ss"
							onChange={this.sendLaterDateChange}
							filterTime={filterTime}
						/>
					</Form.Group>
				</Col>
			</Row>

			submitButtonlabel = 'Compose Campaign';
		}

		let senderIdDropdown = '';
		if (!isEmpty(senderIdList) && (!('error' in senderIdList))) {
			senderIdDropdown = senderIdList.map((obj, index) => {
				return <option value={obj.cli} key={`senderid${index}`} >{obj.cli}</option>
			})

		} else {
			senderIdDropdown = <option value="" >No ID found</option>
		}

		/*let channelDropdown = '';
		if (!isEmpty(channelList) && (!('error' in channelList))) {
			channelDropdown = channelList.map((obj, index) => {
				return <option value={obj.slot} key={`channel${index}`} >{obj.slot}</option>
			})

		} else {
			channelDropdown = <option value="" >No Channel found</option>
		}*/

		let baseUploadDropdown = '';

		if (!isEmpty(baseUploads) && (!('error' in baseUploads))) {
			baseUploadDropdown = baseUploads.map((fileObj, index) => {
				return <option value={fileObj.baseId} key={`basefile${index}`} >{fileObj.baseName}</option>
			})

		} else {
			baseUploadDropdown = <option value="" >No Files</option>
		}

		let welcomeDropdown = '';
		let menuDropdown = '';
		let noinputDropdown = '';
		let wronginputDropdown = '';
		let thanksDropdown = '';
		let optinDropdown = '';
		let optoutDropdown = '';
		let actionDropdown = '';

		if (!isEmpty(promptUploads) && (!('error' in promptUploads))) {

			welcomeDropdown = promptUploads.map((fileObj, index) => {
				if (fileObj.promptCategory === 'welcome') {
					return <option value={fileObj.promptId} key={`promptfile${index}`} >{fileObj.fileName}</option>
				}
				else {
					welcomeDropdown = <option value="" >No Files</option>
				}
			})

			menuDropdown = promptUploads.map((fileObj, index) => {
				if (fileObj.promptCategory === 'menu') {
					return <option value={fileObj.promptId} key={`promptfile${index}`} >{fileObj.fileName}</option>
				}
				else {
					menuDropdown = <option value="" >No Files</option>
				}
			})

			noinputDropdown = promptUploads.map((fileObj, index) => {
				if (fileObj.promptCategory === 'noinput') {
					return <option value={fileObj.promptId} key={`promptfile${index}`} >{fileObj.fileName}</option>
				}
				else {
					noinputDropdown = <option value="" >No Files</option>
				}
			})

			wronginputDropdown = promptUploads.map((fileObj, index) => {
				if (fileObj.promptCategory === 'wronginput') {
					return <option value={fileObj.promptId} key={`promptfile${index}`} >{fileObj.fileName}</option>
				}
				else {
					wronginputDropdown = <option value="" >No Files</option>
				}
			})

			thanksDropdown = promptUploads.map((fileObj, index) => {
				if (fileObj.promptCategory === 'thanks') {
					return <option value={fileObj.promptId} key={`promptfile${index}`} >{fileObj.fileName}</option>
				}
				else {
					thanksDropdown = <option value="" >No Files</option>
				}
			})

			optinDropdown = promptUploads.map((fileObj, index) => {
				if (fileObj.promptCategory === 'optin') {
					return <option value={fileObj.promptId} key={`promptfile${index}`} >{fileObj.fileName}</option>
				}
				else {
					optinDropdown = <option value="" >No Files</option>
				}
			})
			optoutDropdown = promptUploads.map((fileObj, index) => {
				if (fileObj.promptCategory === 'optout') {
					return <option value={fileObj.promptId} key={`promptfile${index}`} >{fileObj.fileName}</option>
				}
				else {
					optoutDropdown = <option value="" >No Files</option>
				}
			})
			actionDropdown = promptUploads.map((fileObj, index) => {
				if (fileObj.promptCategory === 'action') {
					return <option value={fileObj.promptId} key={`promptfile${index}`} >{fileObj.fileName}</option>
				}
				else {
					actionDropdown = <option value="" >No Files</option>
				}
			})
		}

		return (

			<Form className="add-campaign-form" onSubmit={(e) => this.handleAddCampaignSubmit(e)}>
				<Row>
					<Col sm={12}>
						<Card>
							<Card.Header>Compose Voice Campaign</Card.Header>
							<Card.Body>
								<Row>
									<Col sm={12}>
										<Form.Group >
											<Form.Label>IVR Template</Form.Label>
											<div>
												<Form.Check required id="templateId-0" custom="true" inline checked={this.state.templateId === "0"} label="Simple IVR" name="templateId" onChange={e => this.setTemplate(e)} value="0" type="radio" />
												<Form.Check required id="templateId-1" custom="true" inline checked={this.state.templateId === "1"} label="DTMF Input" name="templateId" onChange={e => this.setTemplate(e)} value="1" type="radio" />
												<Form.Check required id="templateId-2" custom="true" inline checked={this.state.templateId === "2"} label="Call Patch" name="templateId" onChange={e => this.setTemplate(e)} value="2" type="radio" />
												<Form.Check required id="templateId-3" custom="true" inline checked={this.state.templateId === "3"} label="Automobile Service" name="templateId" onChange={e => this.setTemplate(e)} value="3" type="radio" />
												<Form.Check required id="templateId-4" custom="true" inline checked={this.state.templateId === "4"} label="Premium Reminder" name="templateId" onChange={e => this.setTemplate(e)} value="4" type="radio" />
												<Form.Check required id="templateId-5" custom="true" inline checked={this.state.templateId === "5"} label="Service Reminder" name="templateId" onChange={e => this.setTemplate(e)} value="5" type="radio" />
												<Form.Check required id="templateId-5" custom="true" inline checked={this.state.templateId === "6"} label="Consultancy" name="templateId" onChange={e => this.setTemplate(e)} value="6" type="radio" />
											</div>
										</Form.Group>
									</Col>
								</Row>
								{(this.state.templateId === '3') &&
									<Row>
										<Col sm={4}>
											<Form.Group>
												<Form.Select as="select" required name="templateName" onChange={e => this.setvalue(e)} value={this.state.templateName}>
													<option value="">Select Template</option>
													<option value="auto1">Service Scheduling</option>
													<option value="auto2">Test Drive Follow up</option>
													<option value="auto3">Service Reminder</option>
												</Form.Select>
											</Form.Group>
										</Col>
								</Row>}
								<Row>
									<Col sm={4}>
										<Form.Group controlId="campaign-name">
											<Form.Control required name="campaignName" onChange={e => this.setvalue(e)} value={this.state.campaignName} type="text" placeholder="Campaign Name" />
										</Form.Group>
									</Col>
								</Row>
								{(this.state.templateId === '0') && <Row>
									<Col sm={4}>
										<Form.Select as="select" required name="cli" onChange={e => this.setvalue(e)}>
											<option value="">Select CLI</option>
											{senderIdDropdown}
										</Form.Select>
									</Col>
								</Row>}
								{(this.state.templateId === '1') && <Row>
									<Col sm={4}>
										<Form.Select as="select" required name="cli" onChange={e => this.setvalue(e)}>
											<option value="">Select CLI</option>
											{senderIdDropdown}
										</Form.Select>
									</Col>
								</Row>}
								{(this.state.templateId === '2') && <Row>
									<Col sm={4}>
										<Form.Select as="select" required name="cli" onChange={e => this.setvalue(e)}>
											<option value="">Select CLI</option>
											{senderIdDropdown}
										</Form.Select>
									</Col>
								</Row>}
								{(this.state.templateId === '3') && <Row>
									<Col sm={4}>
										<Form.Select as="select" required name="cli" onChange={e => this.setvalue(e)}>
											<option value="">Select CLI</option>
											{senderIdDropdown}
										</Form.Select>
									</Col>
								</Row>}
								{(this.state.templateId === '4') && <Row>
									<Col sm={4}>
										<Form.Select as="select" required name="cli" onChange={e => this.setvalue(e)}>
											<option value="">Select CLI</option>
											{senderIdDropdown}
										</Form.Select>
									</Col>
								</Row>}
								{(this.state.templateId === '5') && <Row>
									<Col sm={4}>
										<Form.Select as="select" required name="cli" onChange={e => this.setvalue(e)}>
											<option value="">Select CLI</option>
											{senderIdDropdown}
										</Form.Select>
									</Col>
								</Row>}
								{(this.state.templateId === '6') && <Row>
									<Col sm={4}>
										<Form.Select as="select" required name="cli" onChange={e => this.setvalue(e)}>
											<option value="">Select CLI</option>
											{senderIdDropdown}
										</Form.Select>
									</Col>
								</Row>}
								{(this.state.templateId === '1') &&
									<Row>
										<Col sm={4}>
											<Form.Group controlId="sender-id">
												<Form.Label>Select DTMF</Form.Label>
												<Form.Select required name="dtmf" onChange={(e) => { this.handleSelect(e.target.selectedOptions) }} multiple as="select">
													<option value="">Select</option>
													<option value="0">0</option>
													<option value="1">1</option>
													<option value="2">2</option>
													<option value="3">3</option>
													<option value="4">4</option>
													<option value="5">5</option>
													<option value="6">6</option>
													<option value="7">7</option>
													<option value="8">8</option>
													<option value="9">9</option>
													<option value="*">*</option>
												</Form.Select>
											</Form.Group>
										</Col>
									</Row>}
									{(this.state.templateId === '0') && <Row>
									<Col sm={8}>
										<Form.Label>Upload Contacts</Form.Label>
										<Tabs defaultActiveKey="contactList" className="" onSelect={k => this.setUploadType(k, 'baseFileType')}>
											<Tab eventKey="contactList" title="Contact Numbers">
												<Form.Group >
													<Form.Control onChange={e => this.setvalue(e)} name="contactList" as="textarea" rows="3" />
												</Form.Group>
											</Tab>
											<Tab eventKey="newBaseFile" title="Upload Contacts File(.csv OR .txt format only)">
												<Form.Group >
													<Form.Control name="newContactsFile" onChange={this.onFileChangeHandler} ref={this.baseFileInput} type="file" />
												</Form.Group>
												<Form.Group>
													<Form.Control name="baseName" onChange={e => this.setvalue(e)} value={this.state.baseName} type="text" placeholder="Base File Name" />
												</Form.Group>
											</Tab>
											<Tab eventKey="oldBaseFile" title="Existing Contacts">
												<Form.Group >
													{/* <Form.Label>Select File</Form.Label> */}
													<Form.Select as="select" name="oldBaseId" onChange={e => this.setvalue(e)} >
														<option value="">Select File</option>
														{baseUploadDropdown}
													</Form.Select>
												</Form.Group>
											</Tab>
										</Tabs>
									</Col>
								</Row>}
								{(this.state.templateId === '1') && <Row>
									<Col sm={8}>
										<Form.Label>Upload Contacts</Form.Label>
										<Tabs defaultActiveKey="contactList" className="" onSelect={k => this.setUploadType(k, 'baseFileType')}>
											<Tab eventKey="contactList" title="Contact Numbers">
												<Form.Group >
													<Form.Control onChange={e => this.setvalue(e)} name="contactList" as="textarea" rows="3" />
												</Form.Group>
											</Tab>
											<Tab eventKey="newBaseFile" title="Upload Contacts File(.csv OR .txt format only)">
												<Form.Group >
													<Form.Control name="newContactsFile" onChange={this.onFileChangeHandler} ref={this.baseFileInput} type="file" />
												</Form.Group>
												<Form.Group>
													<Form.Control name="baseName" onChange={e => this.setvalue(e)} value={this.state.baseName} type="text" placeholder="Base File Name" />
												</Form.Group>
											</Tab>
											<Tab eventKey="oldBaseFile" title="Existing Contacts">
												<Form.Group >
													{/* <Form.Label>Select File</Form.Label> */}
													<Form.Select as="select" name="oldBaseId" onChange={e => this.setvalue(e)} >
														<option value="">Select File</option>
														{baseUploadDropdown}
													</Form.Select>
												</Form.Group>
											</Tab>
										</Tabs>
									</Col>
								</Row>}
								{(this.state.templateId === '2') && <Row>
									<Col sm={8}>
										<Form.Label>Upload Contacts</Form.Label>
										<Tabs defaultActiveKey="contactList" className="" onSelect={k => this.setUploadType(k, 'baseFileType')}>
											<Tab eventKey="contactList" title="Contact Numbers">
												<Form.Group >
													<Form.Control onChange={e => this.setvalue(e)} name="contactList" as="textarea" rows="3" />
												</Form.Group>
											</Tab>
											<Tab eventKey="newBaseFile" title="Upload Contacts File(.csv OR .txt format only)">
												<Form.Group >
													<Form.Control name="newContactsFile" onChange={this.onFileChangeHandler} ref={this.baseFileInput} type="file" />
												</Form.Group>
												<Form.Group>
													<Form.Control name="baseName" onChange={e => this.setvalue(e)} value={this.state.baseName} type="text" placeholder="Base File Name" />
												</Form.Group>
											</Tab>
											<Tab eventKey="oldBaseFile" title="Existing Contacts">
												<Form.Group >
													{/* <Form.Label>Select File</Form.Label> */}
													<Form.Select as="select" name="oldBaseId" onChange={e => this.setvalue(e)} >
														<option value="">Select File</option>
														{baseUploadDropdown}
													</Form.Select>
												</Form.Group>
											</Tab>
										</Tabs>
									</Col>
								</Row>}
								{(this.state.templateId === '3') && <Row>
									<Col sm={8}>
										<Form.Label>Upload Contacts <a className="btn btn-sm btn-primary" onClick={( e ) => { this.downloadCsv(this.state.templateId,this.state.templateName) }} href="#">Download Sample CSV</a></Form.Label>
										<Tabs defaultActiveKey="contactList" className="" onSelect={k => this.setUploadType(k, 'baseFileType')}>
											<Tab eventKey="contactList" title="Contact Numbers">
												<Form.Group >
													<Form.Control onChange={e => this.setvalue(e)} name="contactList" as="textarea" rows="3" />
												</Form.Group>
											</Tab>
											<Tab eventKey="newBaseFile" title="Upload Contacts File(.csv OR .txt format only)">
												<Form.Group >
													<Form.Control name="newContactsFile" onChange={this.onFileChangeHandler} ref={this.baseFileInput} type="file" />
												</Form.Group>
												<Form.Group>
													<Form.Control name="baseName" onChange={e => this.setvalue(e)} value={this.state.baseName} type="text" placeholder="Base File Name" />
												</Form.Group>
											</Tab>
											<Tab eventKey="oldBaseFile" title="Existing Contacts">
												<Form.Group >
													{/* <Form.Label>Select File</Form.Label> */}
													<Form.Select as="select" name="oldBaseId" onChange={e => this.setvalue(e)} >
														<option value="">Select File</option>
														{baseUploadDropdown}
													</Form.Select>
												</Form.Group>
											</Tab>
										</Tabs>
									</Col>
								</Row>}
								{(this.state.templateId === '4') && <Row>
									<Col sm={8}>
										<Form.Label>Upload Contacts <a className="btn btn-sm btn-primary" onClick={( e ) => { this.downloadCsv(this.state.templateId,this.state.templateName) }} href="#">Download Sample CSV</a></Form.Label>
										<Tabs defaultActiveKey="contactList" className="" onSelect={k => this.setUploadType(k, 'baseFileType')}>
											<Tab eventKey="contactList" title="Contact Numbers">
												<Form.Group >
													<Form.Control onChange={e => this.setvalue(e)} name="contactList" as="textarea" rows="3" />
												</Form.Group>
											</Tab>
											<Tab eventKey="newBaseFile" title="Upload Contacts File(.csv OR .txt format only)">
												<Form.Group >
													<Form.Control name="newContactsFile" onChange={this.onFileChangeHandler} ref={this.baseFileInput} type="file" />
												</Form.Group>
												<Form.Group>
													<Form.Control name="baseName" onChange={e => this.setvalue(e)} value={this.state.baseName} type="text" placeholder="Base File Name" />
												</Form.Group>
											</Tab>
											<Tab eventKey="oldBaseFile" title="Existing Contacts">
												<Form.Group >
													{/* <Form.Label>Select File</Form.Label> */}
													<Form.Select as="select" name="oldBaseId" onChange={e => this.setvalue(e)} >
														<option value="">Select File</option>
														{baseUploadDropdown}
													</Form.Select>
												</Form.Group>
											</Tab>
										</Tabs>
									</Col>
								</Row>}
								{(this.state.templateId === '5') && <Row>
									<Col sm={8}>
										<Form.Label>Upload Contacts <a className="btn btn-sm btn-primary" onClick={( e ) => { this.downloadCsv(this.state.templateId,this.state.templateName) }} href="#">Download Sample CSV</a></Form.Label>
										<Tabs defaultActiveKey="contactList" className="" onSelect={k => this.setUploadType(k, 'baseFileType')}>
											<Tab eventKey="contactList" title="Contact Numbers">
												<Form.Group >
													<Form.Control onChange={e => this.setvalue(e)} name="contactList" as="textarea" rows="3" />
												</Form.Group>
											</Tab>
											<Tab eventKey="newBaseFile" title="Upload Contacts File(.csv OR .txt format only)">
												<Form.Group >
													<Form.Control name="newContactsFile" onChange={this.onFileChangeHandler} ref={this.baseFileInput} type="file" />
												</Form.Group>
												<Form.Group>
													<Form.Control name="baseName" onChange={e => this.setvalue(e)} value={this.state.baseName} type="text" placeholder="Base File Name" />
												</Form.Group>
											</Tab>
											<Tab eventKey="oldBaseFile" title="Existing Contacts">
												<Form.Group >
													{/* <Form.Label>Select File</Form.Label> */}
													<Form.Select as="select" name="oldBaseId" onChange={e => this.setvalue(e)} >
														<option value="">Select File</option>
														{baseUploadDropdown}
													</Form.Select>
												</Form.Group>
											</Tab>
										</Tabs>
									</Col>
								</Row>}
								{(this.state.templateId === '6') && <Row>
									<Col sm={8}>
										<Form.Label>Upload Contacts <a className="btn btn-sm btn-primary" onClick={( e ) => { this.downloadCsv(this.state.templateId,this.state.templateName) }} href="#">Download Sample CSV</a></Form.Label>
										<Tabs defaultActiveKey="contactList" className="" onSelect={k => this.setUploadType(k, 'baseFileType')}>
											<Tab eventKey="contactList" title="Contact Numbers">
												<Form.Group >
													<Form.Control onChange={e => this.setvalue(e)} name="contactList" as="textarea" rows="3" />
												</Form.Group>
											</Tab>
											<Tab eventKey="newBaseFile" title="Upload Contacts File(.csv OR .txt format only)">
												<Form.Group >
													<Form.Control name="newContactsFile" onChange={this.onFileChangeHandler} ref={this.baseFileInput} type="file" />
												</Form.Group>
												<Form.Group>
													<Form.Control name="baseName" onChange={e => this.setvalue(e)} value={this.state.baseName} type="text" placeholder="Base File Name" />
												</Form.Group>
											</Tab>
											<Tab eventKey="oldBaseFile" title="Existing Contacts">
												<Form.Group >
													{/* <Form.Label>Select File</Form.Label> */}
													<Form.Select as="select" name="oldBaseId" onChange={e => this.setvalue(e)} >
														<option value="">Select File</option>
														{baseUploadDropdown}
													</Form.Select>
												</Form.Group>
											</Tab>
										</Tabs>
									</Col>
								</Row>}
								<Row>
									<Col sm={6}>
										<Form.Group ></Form.Group>
									</Col>
								</Row>
								{(this.state.templateId === '1') && <Row>
									<Col sm={3}>
										<Form.Label>Upload Menu Wav File</Form.Label>
										<Form.Group>
											<Form.Select as="select" required name="menuId" onChange={e => this.setvalue(e)}>
												<option value="">Select File</option>
												{menuDropdown}
											</Form.Select>
										</Form.Group>
									</Col>
									<Col sm={3}>
											<Form.Label>Menu Wait Time</Form.Label>
											<Form.Group controlId="sender-id">
												<Form.Select required name="menuWaitTime" onChange={e => this.setvalue(e)} value={this.state.menuWaitTime} as="select">
													<option value="">Select Menu wait time</option>
													<option value="0">0</option>
													<option value="1">1</option>
													<option value="2">2</option>
													<option value="3">3</option>
													<option value="4">4</option>
													<option value="5">5</option>
													<option value="6">6</option>
													<option value="7">7</option>
													<option value="8">8</option>
													<option value="9">9</option>
												</Form.Select>
											</Form.Group>
									</Col>
									<Col sm={3}>
											<Form.Label>Re-Prompt</Form.Label>
											<Form.Group>
												<Form.Select required name="rePrompt" onChange={e => this.setvalue(e)} value={this.state.rePrompt} as="select">
													<option value="">Select Re-Prompt Count</option>
													<option value="0">0</option>
													<option value="1">1</option>
													<option value="2">2</option>
													<option value="3">3</option>
												</Form.Select>
											</Form.Group>
									</Col>
								</Row>}

								{(this.state.templateId === '2') && <Row>
									<Col sm={3}>
										<Form.Label>Upload Menu Wav File</Form.Label>
										<Form.Group>
											<Form.Select as="select" required name="menuId" onChange={e => this.setvalue(e)}>
												<option value="">Select File</option>
												{menuDropdown}
											</Form.Select>
										</Form.Group>
									</Col>
									<Col sm={3}>
											<Form.Label>Menu Wait Time</Form.Label>
											<Form.Group controlId="sender-id">
												<Form.Select required name="menuWaitTime" onChange={e => this.setvalue(e)} value={this.state.menuWaitTime} as="select">
													<option value="">Select Menu wait time</option>
													<option value="0">0</option>
													<option value="1">1</option>
													<option value="2">2</option>
													<option value="3">3</option>
													<option value="4">4</option>
													<option value="5">5</option>
													<option value="6">6</option>
													<option value="7">7</option>
													<option value="8">8</option>
													<option value="9">9</option>
												</Form.Select>
											</Form.Group>
									</Col>
									<Col sm={3}>
											<Form.Label>Re-Prompt</Form.Label>
											<Form.Group>
												<Form.Select required name="rePrompt" onChange={e => this.setvalue(e)} value={this.state.rePrompt} as="select">
													<option value="">Select Re-Prompt Count</option>
													<option value="0">0</option>
													<option value="1">1</option>
													<option value="2">2</option>
													<option value="3">3</option>
												</Form.Select>
											</Form.Group>
									</Col>
								</Row>}

								<Row>
									<Col sm={6}>
										<Form.Group ></Form.Group>
									</Col>
								</Row>

								{this.state.templateId === '0' && <Row>
									<Col sm={4}>
										<Form.Label>Upload Wav File</Form.Label>
										<Form.Select as="select" required name="welcomeId" onChange={e => this.setvalue(e)}>
											<option value="">Select File</option>
											{welcomeDropdown}
										</Form.Select>
									</Col>
								</Row>}

								{(this.state.templateId === '3' && this.state.templateName === 'auto1') && <Row>
									<Form.Label>Welcome Section (Welcome Prompts Category)</Form.Label>
									<Col sm={3}>
										<Form.Select as="select" required name="menu1" onChange={e => this.setvalue(e)}>
											<option value="">Select Voice File Part 1</option>
											{welcomeDropdown}
										</Form.Select>
									</Col>
									<Col sm={2}>
										<Form.Select disabled as="select" required name="date1" onChange={e => this.setvalue(e)}>
											<option value={this.state.date1='1'}>Date Part 1</option>
										</Form.Select>
									</Col>
									<Col sm={3}>
										<Form.Select as="select" required name="menu2" onChange={e => this.setvalue(e)}>
											<option value="">Select Voice File Part 2</option>
											{welcomeDropdown}
										</Form.Select>
									</Col>
								</Row>}

								{(this.state.templateId === '3' && this.state.templateName === 'auto1') && <Row>
									<Form.Label>Thanks Section (Optin Prompts Category)</Form.Label>
									<Col sm={3}>
										<Form.Select as="select" required name="optin1" onChange={e => this.setvalue(e)}>
											<option value="">Select Voice File Part 1</option>
											{optinDropdown}
										</Form.Select>
									</Col>
									<Col sm={2}>
										<Form.Select disabled as="select" required name="date2" onChange={e => this.setvalue(e)}>
											<option value={this.state.date2='2'}>Date Part 2</option>
										</Form.Select>
									</Col>
									<Col sm={3}>
										<Form.Select as="select" required name="optin2" onChange={e => this.setvalue(e)}>
											<option value="">Select Voice File Part 2</option>
											{optinDropdown}
										</Form.Select>
									</Col>
								</Row>}

								{(this.state.templateId === '3' && this.state.templateName === 'auto2') && <Row>
									<Form.Label>Welcome Section (Welcome Prompts Category)</Form.Label>
									<Col sm={3}>

										<Form.Select as="select" required name="menu1" onChange={e => this.setvalue(e)}>
											<option value="">Select Voice File Part 1</option>
											{welcomeDropdown}
										</Form.Select>
									</Col>
									<Col sm={3}>
										<Form.Select disabled as="select" required name="token1" onChange={e => this.setvalue(e)}>
											<option value={this.state.token1='1'}>Car Name</option>
										</Form.Select>
									</Col>
									<Col sm={3}>
										<Form.Select as="select" required name="menu2" onChange={e => this.setvalue(e)}>
											<option value="">Select Voice File Part 2</option>
											{welcomeDropdown}
										</Form.Select>
									</Col>
								</Row>}

								{(this.state.templateId === '3' && this.state.templateName === 'auto2') && <Row>
									<Form.Label>Thanks Section (Optin Prompts Category)</Form.Label>
									<Col sm={3}>
										<Form.Select as="select" required name="optin1" onChange={e => this.setvalue(e)}>
											<option value="">Select Voice File</option>
											{optinDropdown}
										</Form.Select>
									</Col>
								</Row>}

								{(this.state.templateId === '3' && this.state.templateName === 'auto3') && <Row>
									<Form.Label>Welcome Section (Welcome Prompts Category)</Form.Label>
									<Col sm={3}>
										<Form.Select as="select" required name="menu1" onChange={e => this.setvalue(e)}>
											<option value="">Select Voice File Part 1</option>
											{welcomeDropdown}
										</Form.Select>
									</Col>
									<Col sm={2}>
										<Form.Select disabled as="select" required name="date1" onChange={e => this.setvalue(e)}>
											<option value={this.state.date1='1'}>Date Part 1</option>
										</Form.Select>
									</Col>
									<Col sm={3}>
										<Form.Select as="select" required name="menu2" onChange={e => this.setvalue(e)}>
											<option value="">Select Voice File Part 2</option>
											{welcomeDropdown}
										</Form.Select>
									</Col>
								</Row>}

								{(this.state.templateId === '3' && this.state.templateName === 'auto3') && <Row>
									<Form.Label>Optin Section (Optin Prompts Category)</Form.Label>
									<Col sm={3}>
										<Form.Select as="select" required name="optin1" onChange={e => this.setvalue(e)}>
											<option value="">Select Voice File</option>
											{optinDropdown}
										</Form.Select>
									</Col>
								</Row>}

								{(this.state.templateId === '3' && this.state.templateName === 'auto3') && <Row>
									<Form.Label>Optout Section (Optout Prompts Category)</Form.Label>
									<Col sm={3}>
										<Form.Select as="select" required name="optout1" onChange={e => this.setvalue(e)}>
											<option value="">Select Voice File Part 1</option>
											{optoutDropdown}
										</Form.Select>
									</Col>
									<Col sm={2}>
										<Form.Select disabled as="select" required name="date2" onChange={e => this.setvalue(e)}>
											<option value={this.state.date2='2'}>Date Part 2</option>
										</Form.Select>
									</Col>
									<Col sm={3}>
										<Form.Select as="select" required name="optout2" onChange={e => this.setvalue(e)}>
											<option value="">Select Voice File Part 2</option>
											{optoutDropdown}
										</Form.Select>
									</Col>
								</Row>}

								{(this.state.templateId === '3' && this.state.templateName === 'auto3') && <Row>
									<Form.Label>Action Section (Action Prompts Category)</Form.Label>
									<Col sm={3}>
										<Form.Select as="select" required name="action1" onChange={e => this.setvalue(e)}>
											<option value="">Select Voice File Part 1</option>
											{actionDropdown}
										</Form.Select>
									</Col>
									<Col sm={2}>
										<Form.Select disabled as="select" required name="date3" onChange={e => this.setvalue(e)}>
											<option value={this.state.date3='3'}>Date Part 3</option>
										</Form.Select>
									</Col>
									<Col sm={3}>
										<Form.Select as="select" required name="action2" onChange={e => this.setvalue(e)}>
											<option value="">Select Voice File Part 2</option>
											{actionDropdown}
										</Form.Select>
									</Col>
								</Row>}

								{(this.state.templateId === '4') && <Row>
									<Form.Label>Welcome Section (Welcome Prompts Category)</Form.Label>
									<Col sm={3}>

										<Form.Select as="select" required name="menu1" onChange={e => this.setvalue(e)}>
											<option value="">Select Voice File Part 1</option>
											{welcomeDropdown}
										</Form.Select>
									</Col>
									<Col sm={3}>
										<Form.Select disabled as="select" required name="amount1" onChange={e => this.setvalue(e)}>
											<option value={this.state.amount1='1'}>Amount Part</option>
										</Form.Select>
									</Col>
									<Col sm={3}>
										<Form.Select as="select" required name="menu2" onChange={e => this.setvalue(e)}>
											<option value="">Select Voice File Part 2</option>
											{welcomeDropdown}
										</Form.Select>
									</Col>
									</Row>}
									{(this.state.templateId === '4') && <Row>
									<Col sm={3}>
										<Form.Select disabled as="select" required name="date1" onChange={e => this.setvalue(e)}>
											<option value={this.state.date1='2'}>Date Part</option>
										</Form.Select>
									</Col>
									<Col sm={3}>
										<Form.Select as="select" required name="menu3" onChange={e => this.setvalue(e)}>
											<option value="">Select Voice File Part 3</option>
											{welcomeDropdown}
										</Form.Select>
									</Col>
								</Row>}

								{(this.state.templateId === '5') && <Row>
									<Form.Label>Welcome Section (Welcome Prompts Category)</Form.Label>
									<Col sm={3}>

										<Form.Select as="select" required name="menu1" onChange={e => this.setvalue(e)}>
											<option value="">Select Voice File Part 1</option>
											{welcomeDropdown}
										</Form.Select>
									</Col>
									<Col sm={3}>
										<Form.Select disabled as="select" required name="company1" onChange={e => this.setvalue(e)}>
											<option value={this.state.company1='1'}>Client Name Part</option>
										</Form.Select>
									</Col>
									<Col sm={3}>
										<Form.Select as="select" required name="menu2" onChange={e => this.setvalue(e)}>
											<option value="">Select Voice File Part 2</option>
											{welcomeDropdown}
										</Form.Select>
									</Col>
									</Row>}
									{(this.state.templateId === '5') && <Row>
									<Col sm={3}>
										<Form.Select disabled as="select" required name="service1" onChange={e => this.setvalue(e)}>
											<option value={this.state.service1='2'}>Service Name Part</option>
										</Form.Select>
									</Col>
									<Col sm={3}>
										<Form.Select as="select" required name="menu3" onChange={e => this.setvalue(e)}>
											<option value="">Select Voice File Part 3</option>
											{welcomeDropdown}
										</Form.Select>
									</Col>
									<Col sm={3}>
										<Form.Select disabled as="select" required name="amount1" onChange={e => this.setvalue(e)}>
											<option value={this.state.amount1='3'}>Amount Part</option>
										</Form.Select>
									</Col>
								</Row>}
								{(this.state.templateId === '5') && <Row>
									<Col sm={3}>
										<Form.Select as="select" required name="menu4" onChange={e => this.setvalue(e)}>
											<option value="">Select Voice File Part 4</option>
											{welcomeDropdown}
										</Form.Select>
									</Col>
									<Col sm={3}>
										<Form.Select disabled as="select" required name="month1" onChange={e => this.setvalue(e)}>
											<option value={this.state.month1='4'}>Month Part</option>
										</Form.Select>
									</Col>
								</Row>}
								{(this.state.templateId === '6') && <Row>
									<Col sm={3}>
										<Form.Label>Upload Welcome File</Form.Label>
										<Form.Select as="select" required name="welcomeId" onChange={e => this.setvalue(e)}>
											<option value="">Select File</option>
											{welcomeDropdown}
										</Form.Select>
									</Col>
									<Col sm={3}>
										<Form.Label>Upload Menu 1</Form.Label>
										<Form.Group>
											<Form.Select as="select" required name="menu1" onChange={e => this.setvalue(e)}>
												<option value="">Select File</option>
												{menuDropdown}
											</Form.Select>
										</Form.Group>
									</Col>
									<Col sm={3}>
										<Form.Label>Upload Menu 2</Form.Label>
										<Form.Group>
											<Form.Select as="select" required name="menu2" onChange={e => this.setvalue(e)}>
												<option value="">Select File</option>
												{menuDropdown}
											</Form.Select>
										</Form.Group>
									</Col>
									<Col sm={3}>
										<Form.Label>Upload Menu 3</Form.Label>
										<Form.Group>
											<Form.Select as="select" required name="menu3" onChange={e => this.setvalue(e)}>
												<option value="">Select File</option>
												{menuDropdown}
											</Form.Select>
										</Form.Group>
									</Col>
									<Col sm={3}>
										<Form.Label>Upload Menu 4</Form.Label>
										<Form.Group>
											<Form.Select as="select" required name="menu4" onChange={e => this.setvalue(e)}>
												<option value="">Select File</option>
												{menuDropdown}
											</Form.Select>
										</Form.Group>
									</Col>
									<Col sm={3}>
										<Form.Label>Upload Menu 5</Form.Label>
										<Form.Group>
											<Form.Select as="select" required name="menu5" onChange={e => this.setvalue(e)}>
												<option value="">Select File</option>
												{menuDropdown}
											</Form.Select>
										</Form.Group>
									</Col>
									<Col sm={3}>
											<Form.Label>Menu Wait Time</Form.Label>
											<Form.Group controlId="sender-id">
												<Form.Select required name="menuWaitTime" onChange={e => this.setvalue(e)} value={this.state.menuWaitTime} as="select">
													<option value="">Select Menu wait time</option>
													<option value="0">0</option>
													<option value="1">1</option>
													<option value="2">2</option>
													<option value="3">3</option>
													<option value="4">4</option>
													<option value="5">5</option>
													<option value="6">6</option>
													<option value="7">7</option>
													<option value="8">8</option>
													<option value="9">9</option>
												</Form.Select>
											</Form.Group>
									</Col>
									<Col sm={3}>
											<Form.Label>Re-Prompt</Form.Label>
											<Form.Group>
												<Form.Select required name="rePrompt" onChange={e => this.setvalue(e)} value={this.state.rePrompt} as="select">
													<option value="">Select Re-Prompt Count</option>
													<option value="0">0</option>
													<option value="1">1</option>
													<option value="2">2</option>
													<option value="3">3</option>
												</Form.Select>
											</Form.Group>
									</Col>
								</Row>}

								{(this.state.templateId === '1') && <Row>
									<Col sm={6}>
										<Form.Group controlId="welcome-prompt">
											<Form.Check checked={this.state.welcomePrompt} onChange={(e) => this.toggleChange(e, 'welcomePrompt')} custom="true" inline name="welcomePrompt" label="Welcome File" id="welcomePrompt" type="checkbox" />
											<Form.Check checked={this.state.noinputPrompt} onChange={(e) => this.toggleChange(e, 'noinputPrompt')} custom="true" inline name="noinputPrompt" label="Noinput File" id="noinputPrompt" type="checkbox" />
											<Form.Check checked={this.state.wronginputPrompt} onChange={(e) => this.toggleChange(e, 'wronginputPrompt')} custom="true" inline name="wronginputPrompt" label="Wronginput File" id="wronginputPrompt" type="checkbox" />
											<Form.Check checked={this.state.thanksPrompt} onChange={(e) => this.toggleChange(e, 'thanksPrompt')} custom="true" inline name="thanksPrompt" label="Thanks File" id="thanksPrompt" type="checkbox" />
										</Form.Group>
									</Col>
								</Row>}

								{(this.state.templateId === '2') && <Row>
									<Col sm={6}>
										<Form.Group controlId="welcome-prompt">
											<Form.Check checked={this.state.welcomePrompt} onChange={(e) => this.toggleChange(e, 'welcomePrompt')} custom="true" inline name="welcomePrompt" label="Welcome File" id="welcomePrompt" type="checkbox" />
											<Form.Check checked={this.state.noinputPrompt} onChange={(e) => this.toggleChange(e, 'noinputPrompt')} custom="true" inline name="noinputPrompt" label="Noinput File" id="noinputPrompt" type="checkbox" />
											<Form.Check checked={this.state.wronginputPrompt} onChange={(e) => this.toggleChange(e, 'wronginputPrompt')} custom="true" inline name="wronginputPrompt" label="Wronginput File" id="wronginputPrompt" type="checkbox" />
											<Form.Check checked={this.state.thanksPrompt} onChange={(e) => this.toggleChange(e, 'thanksPrompt')} custom="true" inline name="thanksPrompt" label="Thanks File" id="thanksPrompt" type="checkbox" />
										</Form.Group>
									</Col>
								</Row>}

								{(this.state.templateId === '6') && <Row>
									<Col sm={6}>
										<Form.Group controlId="welcome-prompt">
											<Form.Check checked={this.state.noinputPrompt} onChange={(e) => this.toggleChange(e, 'noinputPrompt')} custom="true" inline name="noinputPrompt" label="Noinput File" id="noinputPrompt" type="checkbox" />
											<Form.Check checked={this.state.wronginputPrompt} onChange={(e) => this.toggleChange(e, 'wronginputPrompt')} custom="true" inline name="wronginputPrompt" label="Wronginput File" id="wronginputPrompt" type="checkbox" />
											<Form.Check checked={this.state.thanksPrompt} onChange={(e) => this.toggleChange(e, 'thanksPrompt')} custom="true" inline name="thanksPrompt" label="Thanks File" id="thanksPrompt" type="checkbox" />
										</Form.Group>
									</Col>
								</Row>}

								{(this.state.welcomePrompt) && <Row>
									<Col sm={4}>
										<Form.Label>Upload Welcome Wav File</Form.Label>
										<Form.Group>
											<Form.Select as="select" name="welcomeId" onChange={e => this.setvalue(e)}>
												<option value="">Select File</option>
												{welcomeDropdown}
											</Form.Select>
										</Form.Group>
									</Col>
								</Row>}

								{(this.state.noinputPrompt) && <Row>
									<Col sm={4}>
										<Form.Label>Upload Noinput Wav File</Form.Label>

										<Form.Group>
											{/* <Form.Label>Select File</Form.Label> */}
											<Form.Select as="select" name="noinputId" onChange={e => this.setvalue(e)}>
												<option value="">Select File</option>
												{noinputDropdown}
											</Form.Select>
										</Form.Group>
									</Col>
								</Row>}

								{(this.state.wronginputPrompt) && <Row>
									<Col sm={4}>
										<Form.Label>Upload Wronginput Wav File</Form.Label>

										<Form.Group>
											{/* <Form.Label>Select File</Form.Label> */}
											<Form.Select as="select" name="wronginputId" onChange={e => this.setvalue(e)}>
												<option value="">Select File</option>
												{wronginputDropdown}
											</Form.Select>
										</Form.Group>

									</Col>
								</Row>}

								{(this.state.thanksPrompt) && <Row>
									<Col sm={4}>
										<Form.Label>Upload Thanks Wav File</Form.Label>

										<Form.Group>
											{/* <Form.Label>Select File</Form.Label> */}
											<Form.Select as="select" name="thanksId" onChange={e => this.setvalue(e)}>
												<option value="">Select File</option>
												{thanksDropdown}
											</Form.Select>
										</Form.Group>
									</Col>
								</Row>}
								{(this.state.templateId === '2') && <Row>
									<Col sm={6}>
										<Form.Group ><Form.Label>Add Agents</Form.Label></Form.Group>
									</Col>
								</Row>}

								{(this.state.templateId === '2') &&
									<Row>
										<Col sm={2}>

											<Form.Group controlId="campaign-name">
												<Form.Control required name="agentNumber" onChange={e => this.setvalue(e)} value={this.state.agentNumber} type="text" placeholder="Agent Number" />
											</Form.Group>
										</Col>

										<Col sm={2}>
											<Form.Group controlId="sender-id">
												<Form.Select required name="agentDtmf" onChange={e => this.setvalue(e)} value={this.state.agentDtmf} as="select">
													<option value="">Select DTMF</option>
													<option value="0">0</option>
													<option value="1">1</option>
													<option value="2">2</option>
													<option value="3">3</option>
													<option value="4">4</option>
													<option value="5">5</option>
													<option value="6">6</option>
													<option value="7">7</option>
													<option value="8">8</option>
													<option value="9">9</option>
													<option value="*">*</option>
												</Form.Select>
											</Form.Group>
										</Col>
										<Col>
											<i onClick={this.handleAddRow} className="fa fa-plus-circle plus-icon"></i>
										</Col>
									</Row>}
								{(this.state.templateId === '2') && <Row>
									<Col sm={4}>
										<div className="react-bootstrap-table">

											<table className="table table-striped table-bordered table-sm widgetlist-table">
												<thead>
													<tr>
														<th>Agent Number</th>
														<th>DTMF</th>
														<th>Action</th>
													</tr>
												</thead>
												<tbody>
													{agentsTable}
												</tbody>
											</table>
										</div>
									</Col>
								</Row>}

								{/*<Row>
									<Col sm={3}>
											<Form.Label>Retry Attempts</Form.Label>
											<Form.Group>
												<Form.Select required name="retries" onChange={e => this.setvalue(e)} value={this.state.rePrompt} as="select">
													<option value="">Select Retry Attempts</option>
													<option value="0">0</option>
													<option value="1">1</option>
													<option value="2">2</option>
													<option value="3">3</option>
												</Form.Select>
											</Form.Group>
									</Col>
									<Col sm={3}>
											<Form.Label>Retry Interval(in Minutes)</Form.Label>
											<Form.Group>
												<Form.Select required name="retryInterval" onChange={e => this.setvalue(e)} value={this.state.rePrompt} as="select">
													<option value="">Select Retry Interval</option>
													<option value="0">15</option>
													<option value="1">30</option>
													<option value="2">45</option>
													<option value="3">60</option>
												</Form.Select>
											</Form.Group>
									</Col>
								</Row>*/}
								{this.state.templateId === '0' && <Row>
									<Col sm={3}>
										<Form.Group controlId="Send-Sms">
											<Form.Check checked={this.state.sendSmsSuccess} onChange={( e ) => this.toggleChange( e, 'sendSmsSuccess' )} custom="true" inline name="sendSmsSuccess" label="Send SMS on Success" id="sendSmsSuccess" type="checkbox" />
										</Form.Group>
									</Col>
									{(this.state.sendSmsSuccess) &&
										<Col sm={2}>
											<Form.Group controlId="sender-id">
												<Form.Select required name="callDurationSMS" onChange={e => this.setvalue(e)} value={this.state.callDurationSMS} as="select">
													<option value="">Select Duration</option>
													<option value="1">1</option>
													<option value="2">2</option>
													<option value="3">3</option>
													<option value="4">4</option>
													<option value="5">5</option>
													<option value="6">6</option>
													<option value="7">7</option>
													<option value="8">8</option>
													<option value="9">9</option>
													<option value="10">10</option>
													<option value="11">11</option>
													<option value="12">12</option>
													<option value="13">13</option>
													<option value="14">14</option>
													<option value="15">15</option>
													<option value="16">16</option>
													<option value="17">17</option>
													<option value="18">18</option>
													<option value="19">19</option>
													<option value="20">20</option>
													<option value="21">21</option>
													<option value="22">22</option>
													<option value="23">23</option>
													<option value="24">24</option>
													<option value="25">25</option>
													<option value="26">26</option>
													<option value="27">27</option>
													<option value="28">28</option>
													<option value="29">29</option>
													<option value="30">30</option>
													<option value="31">31</option>
													<option value="32">32</option>
													<option value="33">33</option>
													<option value="34">34</option>
													<option value="35">35</option>
													<option value="36">36</option>
													<option value="37">37</option>
													<option value="38">38</option>
													<option value="39">39</option>
													<option value="40">40</option>
													<option value="41">41</option>
													<option value="42">42</option>
													<option value="43">43</option>
													<option value="44">44</option>
													<option value="45">45</option>
													<option value="46">46</option>
													<option value="47">47</option>
													<option value="48">48</option>
													<option value="49">49</option>
													<option value="50">50</option>
													<option value="51">51</option>
													<option value="52">52</option>
													<option value="53">53</option>
													<option value="54">54</option>
													<option value="55">55</option>
													<option value="56">56</option>
													<option value="57">57</option>
													<option value="58">58</option>
													<option value="59">59</option>
													<option value="50">60</option>
												</Form.Select>
											</Form.Group>
									</Col>}
									{(this.state.sendSmsSuccess) &&
										<Col sm={7}>
											<Form.Group >
												<Form.Label>Note: Please Paste the SMS API here and replace the Mobile No. with &#123;PHONE&#125;</Form.Label>
												<Form.Control value={this.state.smsApiSuccess} onChange={e => this.setvalue( e )} name="smsApiSuccess" as="textarea" rows="2" />
											</Form.Group>
										</Col>}
								</Row>}
								{this.state.templateId === '0'  && <Row>
									<Col sm={3}>
										<Form.Group controlId="Send-Sms">
											<Form.Check checked={this.state.sendSmsFail} onChange={( e ) => this.toggleChange( e, 'sendSmsFail' )} custom="true" inline name="sendSmsFail" label="Send SMS on Fail" id="sendSmsFail" type="checkbox" />
										</Form.Group>
									</Col>
								{(this.state.sendSmsFail) &&
										<Col sm={7}>
											<Form.Group >
											<Form.Label>Note: Please Paste the SMS API here and replace the Mobile No. with &#123;PHONE&#125;</Form.Label>
												<Form.Control value={this.state.smsApiFail} onChange={e => this.setvalue( e )} name="smsApiFail" as="textarea" rows="2" />
											</Form.Group>
										</Col>}
								</Row>}

								{this.state.templateId === '1' && <Row>
									<Col sm={3}>
										<Form.Group controlId="Send-Sms">
											<Form.Check checked={this.state.sendSmsSuccess} onChange={( e ) => this.toggleChange( e, 'sendSmsSuccess' )} custom="true" inline name="sendSmsSuccess" label="Send SMS on Success" id="sendSmsSuccess" type="checkbox" />
										</Form.Group>
									</Col>
									{(this.state.sendSmsSuccess) &&
										<Col sm={2}>
											<Form.Group controlId="sender-id">
												<Form.Select required name="callDurationSMS" onChange={e => this.setvalue(e)} value={this.state.callDurationSMS} as="select">
													<option value="">Select Duration</option>
													<option value="1">1</option>
													<option value="2">2</option>
													<option value="3">3</option>
													<option value="4">4</option>
													<option value="5">5</option>
													<option value="6">6</option>
													<option value="7">7</option>
													<option value="8">8</option>
													<option value="9">9</option>
													<option value="10">10</option>
													<option value="11">11</option>
													<option value="12">12</option>
													<option value="13">13</option>
													<option value="14">14</option>
													<option value="15">15</option>
													<option value="16">16</option>
													<option value="17">17</option>
													<option value="18">18</option>
													<option value="19">19</option>
													<option value="20">20</option>
													<option value="21">21</option>
													<option value="22">22</option>
													<option value="23">23</option>
													<option value="24">24</option>
													<option value="25">25</option>
													<option value="26">26</option>
													<option value="27">27</option>
													<option value="28">28</option>
													<option value="29">29</option>
													<option value="30">30</option>
													<option value="31">31</option>
													<option value="32">32</option>
													<option value="33">33</option>
													<option value="34">34</option>
													<option value="35">35</option>
													<option value="36">36</option>
													<option value="37">37</option>
													<option value="38">38</option>
													<option value="39">39</option>
													<option value="40">40</option>
													<option value="41">41</option>
													<option value="42">42</option>
													<option value="43">43</option>
													<option value="44">44</option>
													<option value="45">45</option>
													<option value="46">46</option>
													<option value="47">47</option>
													<option value="48">48</option>
													<option value="49">49</option>
													<option value="50">50</option>
													<option value="51">51</option>
													<option value="52">52</option>
													<option value="53">53</option>
													<option value="54">54</option>
													<option value="55">55</option>
													<option value="56">56</option>
													<option value="57">57</option>
													<option value="58">58</option>
													<option value="59">59</option>
													<option value="50">60</option>
												</Form.Select>
											</Form.Group>
									</Col>}
									{(this.state.sendSmsSuccess) &&
										<Col sm={7}>
											<Form.Group >
											<Form.Label>Note: Please Paste the SMS API here and replace the Mobile No. with &#123;PHONE&#125;</Form.Label>
												<Form.Control value={this.state.smsApiSuccess} onChange={e => this.setvalue( e )} name="smsApiSuccess" as="textarea" rows="2" />
											</Form.Group>
										</Col>}
								</Row>}
								{this.state.templateId === '1'  && <Row>
									<Col sm={3}>
										<Form.Group controlId="Send-Sms">
											<Form.Check checked={this.state.sendSmsFail} onChange={( e ) => this.toggleChange( e, 'sendSmsFail' )} custom="true" inline name="sendSmsFail" label="Send SMS on Fail" id="sendSmsFail" type="checkbox" />
										</Form.Group>
									</Col>
								{(this.state.sendSmsFail) &&
										<Col sm={7}>
											<Form.Group >
											<Form.Label>Note: Please Paste the SMS API here and replace the Mobile No. with &#123;PHONE&#125;</Form.Label>
												<Form.Control value={this.state.smsApiFail} onChange={e => this.setvalue( e )} name="smsApiFail" as="textarea" rows="2" />
											</Form.Group>
										</Col>}
								</Row>}
								{this.state.templateId === '1'  && <Row>
									<Col sm={3}>
										<Form.Group controlId="Send-Sms">
											<Form.Check checked={this.state.sendSmsDtmf} onChange={( e ) => this.toggleChange( e, 'sendSmsDtmf' )} custom="true" inline name="sendSmsDtmf" label="Send SMS on DTMF" id="sendSmsDtmf" type="checkbox" />
										</Form.Group>
									</Col>
								{(this.state.sendSmsDtmf) &&
										<Col sm={7}>
											<Form.Group >
											<Form.Label>Note: Please Paste the SMS API here and replace the Mobile No. with &#123;PHONE&#125;</Form.Label>
												<Form.Control value={this.state.smsApiDtmf} onChange={e => this.setvalue( e )} name="smsApiDtmf" as="textarea" rows="2" />
											</Form.Group>
										</Col>}
								</Row>}
								{this.state.templateId === '2' && <Row>
									<Col sm={3}>
										<Form.Group controlId="Send-Sms">
											<Form.Check checked={this.state.sendSmsSuccess} onChange={( e ) => this.toggleChange( e, 'sendSmsSuccess' )} custom="true" inline name="sendSmsSuccess" label="Send SMS on Success" id="sendSmsSuccess" type="checkbox" />
										</Form.Group>
									</Col>
									{(this.state.sendSmsSuccess) &&
										<Col sm={2}>
											<Form.Group controlId="sender-id">
												<Form.Select required name="callDurationSMS" onChange={e => this.setvalue(e)} value={this.state.callDurationSMS} as="select">
													<option value="">Select Duration</option>
													<option value="1">1</option>
													<option value="2">2</option>
													<option value="3">3</option>
													<option value="4">4</option>
													<option value="5">5</option>
													<option value="6">6</option>
													<option value="7">7</option>
													<option value="8">8</option>
													<option value="9">9</option>
													<option value="10">10</option>
													<option value="11">11</option>
													<option value="12">12</option>
													<option value="13">13</option>
													<option value="14">14</option>
													<option value="15">15</option>
													<option value="16">16</option>
													<option value="17">17</option>
													<option value="18">18</option>
													<option value="19">19</option>
													<option value="20">20</option>
													<option value="21">21</option>
													<option value="22">22</option>
													<option value="23">23</option>
													<option value="24">24</option>
													<option value="25">25</option>
													<option value="26">26</option>
													<option value="27">27</option>
													<option value="28">28</option>
													<option value="29">29</option>
													<option value="30">30</option>
													<option value="31">31</option>
													<option value="32">32</option>
													<option value="33">33</option>
													<option value="34">34</option>
													<option value="35">35</option>
													<option value="36">36</option>
													<option value="37">37</option>
													<option value="38">38</option>
													<option value="39">39</option>
													<option value="40">40</option>
													<option value="41">41</option>
													<option value="42">42</option>
													<option value="43">43</option>
													<option value="44">44</option>
													<option value="45">45</option>
													<option value="46">46</option>
													<option value="47">47</option>
													<option value="48">48</option>
													<option value="49">49</option>
													<option value="50">50</option>
													<option value="51">51</option>
													<option value="52">52</option>
													<option value="53">53</option>
													<option value="54">54</option>
													<option value="55">55</option>
													<option value="56">56</option>
													<option value="57">57</option>
													<option value="58">58</option>
													<option value="59">59</option>
													<option value="50">60</option>
												</Form.Select>
											</Form.Group>
									</Col>}
									{(this.state.sendSmsSuccess) &&
										<Col sm={7}>
											<Form.Group >
											<Form.Label>Note: Please Paste the SMS API here and replace the Mobile No. with &#123;PHONE&#125;</Form.Label>
												<Form.Control value={this.state.smsApiSuccess} onChange={e => this.setvalue( e )} name="smsApiSuccess" as="textarea" rows="2" />
											</Form.Group>
										</Col>}
								</Row>}
								{this.state.templateId === '2'  && <Row>
									<Col sm={3}>
										<Form.Group controlId="Send-Sms">
											<Form.Check checked={this.state.sendSmsFail} onChange={( e ) => this.toggleChange( e, 'sendSmsFail' )} custom="true" inline name="sendSmsFail" label="Send SMS on Fail" id="sendSmsFail" type="checkbox" />
										</Form.Group>
									</Col>
								{(this.state.sendSmsFail) &&
										<Col sm={7}>
											<Form.Group >
											<Form.Label>Note: Please Paste the SMS API here and replace the Mobile No. with &#123;PHONE&#125;</Form.Label>
												<Form.Control value={this.state.smsApiFail} onChange={e => this.setvalue( e )} name="smsApiFail" as="textarea" rows="2" />
											</Form.Group>
										</Col>}
								</Row>}
								{this.state.templateId === '2'  && <Row>
									<Col sm={3}>
										<Form.Group controlId="Send-Sms">
											<Form.Check checked={this.state.sendSmsDtmf} onChange={( e ) => this.toggleChange( e, 'sendSmsDtmf' )} custom="true" inline name="sendSmsDtmf" label="Send SMS on DTMF" id="sendSmsDtmf" type="checkbox" />
										</Form.Group>
									</Col>
								{(this.state.sendSmsDtmf) &&
										<Col sm={7}>
											<Form.Group >
											<Form.Label>Note: Please Paste the SMS API here and replace the Mobile No. with &#123;PHONE&#125;</Form.Label>
												<Form.Control value={this.state.smsApiDtmf} onChange={e => this.setvalue( e )} name="smsApiDtmf" as="textarea" rows="2" />
											</Form.Group>
										</Col>}
								</Row>}
								<Row>
									<Col sm={6}>
										<Form.Group controlId="Send-later">
											<Form.Check checked={this.state.sendlater} onChange={(e) => this.toggleChange(e, 'sendLater')} custom="true" inline name="send-later" label="Schedule Call" id="send-later" type="checkbox" />
										</Form.Group>
									</Col>
								</Row>
								{sendLaterDatepicker}
								<Button variant="primary" type="submit">{submitButtonlabel}</Button>
							</Card.Body>
						</Card>

					</Col>
				</Row>
				<Modal size="sm" show={this.state.showModal} onHide={() => this.setMessageShow(false)}>
					<Modal.Header closeButton>
						<Modal.Title id="example-modal-sizes-title-sm">
							{this.state.modalHeading}
						</Modal.Title>

					</Modal.Header>
					<Modal.Body>{this.state.modalContent} </Modal.Body>
				</Modal>

			</Form>
		);
	}
}

export default AddVoiceCampaign;