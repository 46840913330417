import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Button, Modal, Card } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import paginationFactory, { PaginationProvider, PaginationListStandalone, SizePerPageDropdownStandalone } from 'react-bootstrap-table2-paginator';
import { Fetcher } from "../../Helpers/fetcher.js";
import ReactTooltip from 'react-tooltip';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';

import "./index.css";
class DialerManagement extends Component {
	constructor(props) {

		super(props);
		this.state = {
			dialerList: [],
			confirmDelete: false,
			selectedDialerId: 0,
			showAddCreditModal: false,
			showModal: false,
			modalHeading: 'Status',
			modalContent: '',
		}
	}
	componentDidMount() {
		this.getDialer();
	}


	getDialer = () => {
		const apiPath = this.props.app.apiPath;

		Fetcher.get(apiPath + '/api/obd/dialers/list')
			.then(res => {
				this.setState({
					dialerList: res,
					showModal: false,
					showAddCreditModal: false,
					confirmDelete: false,
				})
			})
			.catch(err => { console.log('Error in fetching Dialer List', err) });
	}

	confirmDelete = (status, dialerId) => {
		this.setState({
			confirmDelete: status,
			selectedDialerId: dialerId
		});
	}

	deleteDialer = () => {
		const dialerId = this.state.selectedDialerId;
		
		const data = {
			
		}

		return Fetcher.post(this.props.app.apiPath + '/api/obd/dialer/'+dialerId, { headers: { "Content-type": "application/json" }, method: 'DELETE', body: JSON.stringify(data) })
			.then(res => {
				alert(res.message);
				this.getDialer();
			})
			.catch(err => {
				console.log('Error in Deleting Dialer', err)

				this.setState({
					showModal: true,
					modalHeading: 'Error',
					modalContent: 'Error in Deleting Dialer, please try again',

				})
			});

	}

	setvalue = (e) => {
		this.setState({
			[e.target.name]: e.target.value,
		});
	}

	setMessageShow = (status) => {
		this.setState({
			showModal: false,
			showAddCreditModal: false,
		});
	}

	showAddCreditModal = (userId) => {
		this.setState({
			showAddCreditModal: true,
			selectedDialerId: userId
		});
	}


	render() {

		const dialerList = this.state.dialerList;
		var columns;
		
			columns = [
				{
					dataField: 'dialerId',
					text: 'Dialer Id',
					sort: false,
					hidden: true
				},
				{
					dataField: 'dialerName',
					text: 'Dialer Name',
					sort: true,
					searchable: true
				},
				{
					dataField: 'dialerIP',
					text: 'IP',
					sort: true,
					searchable: true
				},
				{
					dataField: 'dialerPort',
					text: 'Port',
					sort: true,
					searchable: true
				},
				{
					dataField: 'activeCli',
					text: 'Active CLI',
					sort: true,
					searchable: true
				},
				{
					dataField: 'dialerCalls',
					text: 'Active Calls',
					sort: true,
					searchable: true
				},
				{
					dataField: 'totalChannels',
					text: 'Total Channels',
					sort: true,
					searchable: true
				},
				{
					dataField: 'cps',
					text: 'CPS',
					sort: true,
					searchable: true
				},
				{
					dataField: 'channelReduction',
					text: 'Channel Reduction',
					sort: true,
					searchable: true
				},
				{
					dataField: 'cpsReduction',
					text: 'CPS Reduction',
					sort: true,
					searchable: true
				},
				{
					dataField: 'action',
					text: 'Action',
					sort: false,
					searchable: false
				},];
		
		const dialer = Array.isArray(dialerList) ? dialerList.map((dialers, index) => {
				let action = [ <Link data-tip="Edit Dialer" key={`${index}-edit-dialer`} className="edit-dialer" to={`${this.props.app.urlPrefix}/update-dialer/${dialers.dialerId}`}><i className="fa fa-edit"></i></Link> ];
				action.push(<a data-tip="Delete Dialer" key={`${index}-delete-dialer`} className="delete-dialer" onClick={( e ) => { this.confirmDelete( true, dialers.dialerId ) }} href="#"><i className="fa fa-trash"></i></a>);
				return {
					dialerId: dialers.dialerId,
					dialerName: dialers.dialerName,
					dialerIP: dialers.dialerIP,
					dialerPort: dialers.dialerPort,
					activeCli: dialers.activeCli,
					dialerCalls: dialers.dialerCalls,
					totalChannels: dialers.totalChannels,
					cps: dialers.cps,
					channelReduction: dialers.channelReduction,
					cpsReduction: dialers.cpsReduction,
					action: action
				};
		}) : []
		const { SearchBar, ClearSearchButton } = Search;

		const defaultSorted = [{
			dataField: 'dialerId',
			order: 'desc'
		}];

		const paginationOption = {
			custom: true,
			totalSize: dialer.length,
			sizePerPage: 10,
			sizePerPageList: [10, 25, 50, 100]
		};

		return (
			<Card>
				{/*<Card.Header>Users List<span>&nbsp;&nbsp;&nbsp;</span><span className="float-none avail-credit-cont"><span className="credits-avail">Available Credits: {this.state.adminCredits}</span></span></Card.Header>*/}
				<Card.Header>Dialer List</Card.Header>
				<Card.Body>
					<div className="widget-list-table-cont">
						{/* <h3>Campaign Summary</h3> */}
						{/*{!isEmpty( this.state.dialerList ) && <PaginationProvider pagination={paginationFactory( paginationOption )}>*/}
						{<PaginationProvider pagination={paginationFactory(paginationOption)}>
							{
								({
									paginationProps,
									paginationTableProps
								}) => (
									<ToolkitProvider
										keyField="cliId"
										data={dialer}
										columns={columns}
										search
										bootstrap4

									>
										{
											props => (
												<div className="">
													Show <SizePerPageDropdownStandalone className="search-dropdown" {...paginationProps} /> items
													<span className="search-cont"><SearchBar  {...props.searchProps} /></span>
													<ClearSearchButton className="btn-primary btn-round" {...props.searchProps} />
													{/*<span>&nbsp;&nbsp;&nbsp;</span><span className="float-none avail-credit-cont"><span className="credits-avail">Available Credits: {this.state.adminCredits}</span></span>*/}
													{/*<Link className="float-right btn btn-default btn-primary" to={`${this.props.app.urlPrefix}/add-cli`}>Allocate CLI</Link>*/}
													<hr />
													<BootstrapTable defaultSorted={defaultSorted} bordered={true} hover condensed wrapperClasses="table-responsive" classes="widgetlist-table"  {...props.baseProps}  {...paginationTableProps} />
													<PaginationListStandalone {...paginationProps} />
													<ReactTooltip />
												</div>
											)
										}
									</ToolkitProvider>
								)
							}
						</PaginationProvider>}
					</div>

					<Modal size="sm" show={this.state.showModal} onHide={() => this.setMessageShow(false)}>
						<Modal.Header closeButton>
							<Modal.Title id="example-modal-sizes-title-sm">
								{this.state.modalHeading}
							</Modal.Title>
						</Modal.Header>
						<Modal.Body>{this.state.modalContent}</Modal.Body>
					</Modal>

					<Modal size="md" show={this.state.confirmDelete} onHide={() => this.confirmDelete(false, '')}>
						<Modal.Header closeButton>
							<Modal.Title id="example-modal-sizes-title-sm">
								Delete Dialer
							</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							Are you sure you want to delete this Dialer?
						</Modal.Body>
						<Modal.Footer>
							<Button onClick={() => this.confirmDelete(false, '')} variant="secondary">CANCEL</Button>
							<Button onClick={this.deleteDialer} variant="primary">YES</Button>
						</Modal.Footer>
					</Modal>

				</Card.Body>
			</Card>

		);
	}
}

export default DialerManagement;