import React, { Component } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { cloneDeep, isEmpty } from 'lodash';
import { Fetcher } from "../../../Helpers/fetcher.js";
import { Redirect } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../index.css";

// import "./index.css";
class UpdateUser extends Component {
	constructor(props) {
		super(props);
		this.state = {
			name: '',
			emailid: '',
			number: '',
			address: '',
			company: '',
			pincode: '',
			planId: '',
			planName: '',
			accountType: '',
			account: '',
			type: '',
			userType: '',
			channels: '',
			redirectToUserManagement: false,
			showModal: false,
			modalHeading: 'Status',
			modalContent: '',
			planList: [],
			sendDate: '',
			expiryDate: ''
		};

	}
	componentDidMount() {
		const apiPath = this.props.app.apiPath;
		const userId = this.props.selectedUserId;
		const parent = this.props.app.userId;
		// Get Plan List
		Fetcher.get(apiPath + '/api/obd/plans/list/' + parent)
			.then(res => {
				this.setState({
					planList: res
				})
			})
			.catch(err => { console.log('Error in fetching Plans', err) });
			
		Fetcher.get(apiPath + '/api/obd/user/profile/' + userId)
			.then(res => {
				this.setState({
					name: res.name,
					emailid: res.emailid,
					number: res.number,
					address: res.address,
					company: res.company,
					pincode: res.pincode,
					planId: res.planId,
					planName: res.planName,
					userType: res.userType,
					type: res.userType==='user' ? 'User' : 'Reseller',
					accountType: res.accountType,
					account: res.accountType===0 ? 'Promotional': 'Transactional',
					channels: res.channels,
					expiryDate: res.expiryDate
				})
			})
			.catch(err => { console.log('Error in fetching Users data', err) });
	}


	setvalue = (e) => {
		this.setState({
			[e.target.name]: e.target.value,
		});
	}
	hideModal = () => {
		this.setState({
			showModal: false,
			redirectToUserManagement: this.state.redirectToUserManagement
		});
	}
	addZero = (i) => {
		if (i < 10) {
			i = "0" + i;
		}
		return i;
	}
	sendLaterDateChange = date => {
		this.setState({
			sendDate: date,
			expiryDate: date
		});
	};
	updateUser = (event) => {
		event.preventDefault();
		const state = cloneDeep(this.state);
		let date = '';
		let sendDateTime='';
		
		if (state.sendDate == null) {
			alert('Please Select Expiry Date');
			return;
		}
		if(state.sendDate === '')
		{
			sendDateTime = state.expiryDate;
		}
		else
		{
			date = state.sendDate;
			
			sendDateTime = this.addZero(date.getFullYear());
			sendDateTime += "-";
			sendDateTime += this.addZero((date.getMonth() + 1));
			sendDateTime += "-"
			sendDateTime += this.addZero(date.getDate());
			sendDateTime += " 23:59:59"
			/*sendDateTime += this.addZero(date.getHours());
			sendDateTime += ":"
			sendDateTime += this.addZero(date.getMinutes());
			sendDateTime += ":"
			sendDateTime += this.addZero(date.getSeconds());*/
		}
		const data = {
			userId: this.props.selectedUserId,
			name: state.name,
			emailid: state.emailid,
			number: state.number,
			address: state.address,
			company: state.company,
			pincode: state.pincode,
			planId: state.planId,
			accountType: state.accountType,
			userType: state.userType,
			channels: state.channels,
			expiryDate: sendDateTime
		}
		this.saveData(data, false);

	}
	saveData = (data) => {
		return Fetcher.post(this.props.app.apiPath + '/api/obd/user/update', { headers: { "Content-type": "application/json" }, method: 'POST', body: JSON.stringify(data) })
			.then(res => {
				let modalHeading;
				let redirectToUserManagement;
				if (res.message === 'Parameters Incorrect' || res.message === 'User Updation Failed') {
					modalHeading = 'Error';
					redirectToUserManagement = false;
				} else {
					modalHeading = 'Success';
					redirectToUserManagement = true;
					alert(res.message);

				}
				this.setState({
					showModal: true,
					modalHeading: modalHeading,
					modalContent: res.message,
					redirectToUserManagement: redirectToUserManagement
				})

			})
			.catch(err => {
				console.log('Error in Updating Account', err)
				this.setState({
					showModal: true,
					modalHeading: 'Error',
					modalContent: err.message,

				})
			});

	}

	render() {
		if (this.state.redirectToUserManagement === true) {
			return <Redirect to={'/user-list/'} />
		}
		let sendLaterDatepicker='';
		const filterTime = (date) => {
			const isPastTime = new Date().getTime() > date.getTime();
			return !isPastTime;
			};
		sendLaterDatepicker =
				<Col sm={6}>
					<Form.Group className="form-group required">
						<Form.Label>User Expiry</Form.Label>
							<DatePicker
								className="form-control"
								selected={this.state.expiryDate ? new Date(this.state.expiryDate) : this.state.sendDate}
								timeCaption="Time"
								minDate={new Date()}
								dateFormat="yyyy-MM-dd"
								onChange={this.sendLaterDateChange}
								filterTime={filterTime}
							/>
					</Form.Group>
				</Col>


		const planList = cloneDeep(this.state.planList);
		let planDropdown = '';
		if (!isEmpty(planList) && (!('error' in planList))) {
			planDropdown = Array.isArray(planList) ? planList.map((obj, index) => {
				return <option value={obj.planId} key={`planid${index}`} >{obj.planName}</option>
			}) : []

		} else {
			planDropdown = <option>No Plan</option>
		}
			
		return (

			<Form method="post" onSubmit={this.updateUser}>
				<Row>
					<Col sm={12}>
						<Card>
							<Card.Header>Update User</Card.Header>
							<Card.Body>
								<Row>
									<Col sm={3}>
										<Form.Group controlId="referrerDomain">
											<Form.Label>Name</Form.Label>
											<Form.Control required name="name" type="text" placeholder="Name" onChange={e => this.setvalue(e)} value={this.state.name} />
										</Form.Group>
									</Col>
									<Col sm={3}>
										<Form.Group controlId="referrerDomain">
											<Form.Label>Email ID</Form.Label>
											<Form.Control required name="emailid" type="email" placeholder="Email ID" onChange={e => this.setvalue(e)} value={this.state.emailid} />
										</Form.Group>
									</Col>
									<Col sm={3}>
										<Form.Group controlId="referrerDomain">
											<Form.Label>Mobile Number</Form.Label>
											<Form.Control required name="number" type="number" placeholder="Mobile Number" onChange={e => this.setvalue(e)} value={this.state.number} />
										</Form.Group>
									</Col>
								</Row>
								<Row>
									<Col sm={3}>
										<Form.Group controlId="referrerDomain">
											<Form.Label>Address</Form.Label>
											<Form.Control required name="address" type="text" placeholder="Address" onChange={e => this.setvalue( e )} value={this.state.address} />
										</Form.Group>
									</Col>
									<Col sm={3}>
										<Form.Group controlId="referrerDomain">
											<Form.Label>Pincode</Form.Label>
											<Form.Control required name="pincode" type="text" placeholder="Pincode" onChange={e => this.setvalue( e )} value={this.state.pincode} />
										</Form.Group>
									</Col>
									<Col sm={3}>
										<Form.Group controlId="referrerDomain">
											<Form.Label>Company</Form.Label>
											<Form.Control required name="company" type="text" placeholder="Company Name" onChange={e => this.setvalue(e)} value={this.state.company} />
										</Form.Group>
									</Col>
								</Row>
								<Row>
									<Col sm={3}>
										<Form.Group>
											<Form.Label>Update Plan</Form.Label>
											<Form.Select required name="planId" onChange={e => this.setvalue(e)} as="select">
												<option value={this.state.planId}>{this.state.planName}</option>
												{planDropdown}
											</Form.Select>
										</Form.Group>
									</Col>
									<Col sm={3}>
										<Form.Group>
										<Form.Label>Update User Type</Form.Label>
											<Form.Select required name="userType" onChange={e => this.setvalue(e)} as="select">
												<option value={this.state.userType}>{this.state.type}</option>
												<option value="reseller">Reseller</option>
												<option value="user">User</option>
											</Form.Select>
										</Form.Group>
									</Col>
									<Col sm={3}>
										<Form.Group>
											<Form.Label>Update Account Type</Form.Label>
											<Form.Select required name="accountType" onChange={e => this.setvalue(e)} as="select">
												<option value="{this.state.accountType}">{this.state.account}</option>
											</Form.Select>
										</Form.Group>
									</Col>
								</Row>
								<Row>
									<Col sm={3}>
										<Form.Group>
											<Form.Label>Channels</Form.Label>
											<Form.Control required name="channels" type="text" value={this.state.channels} placeholder="No. of Channels" onChange={e => this.setvalue( e )}  />
										</Form.Group>
									</Col>
									{sendLaterDatepicker}
								</Row>

								{this.state.showModal && <div className="login-error">{this.state.modalContent}</div>}
								<Button className="btn-round" variant="primary" type="submit">Update User</Button>
							</Card.Body>
						</Card>
					</Col>
				</Row>
			</Form>
		);
	}

}
export default UpdateUser;