import React from 'react';
import { Link } from 'react-router-dom'
import './index.css';
const SideNav = (props) => {
	let urlPrefix;
	if (props.app.mode === 'dev') {
		urlPrefix = '/build';
	} else {
		urlPrefix = '';
	}
	return (
		<div className="sidebar">
			<div className="scrollbar-container sidebar-nav">
				{props.app.role === 'ROLE_USER' && <ul className="nav">
					<li className="nav-item"><Link className="nav-link active" to="/" aria-current="page"><i className="fa fa-dashboard"></i> Dashboard</Link></li>
					<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/credits-history`}><i className="fa fa-table"></i> Credits History</Link></li>
					<li className="nav-title">Voice Campaigns</li>
					<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/add-voice-campaign`}><i className="fa fa-plus-circle"></i> Compose Voice Campaign</Link></li>
					<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/voice-campaign-summary`}><i className="fa fa-table"></i> Voice Campaign Summary</Link></li>
					<li className="nav-title">Voice Blacklist</li>
					<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/add-voice-blacklist`}><i className="fa fa-plus-circle"></i> Add to Blacklist</Link></li>
					<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/bulk-voice-blacklist`}><i className="fa fa-plus-circle"></i> Add Bulk Blacklist</Link></li>
					<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/voice-blacklist`}><i className="fa fa-table"></i> View Blacklist</Link></li>
					<li className="nav-title">Voice Library</li>
					<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/add-voice-files`}><i className="fa fa-plus-circle"></i> Add Voice File</Link></li>
					<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/voice-files`}><i className="fa fa-table"></i> View Voice Files</Link></li>
				</ul>}

				{(props.app.role === 'ROLE_ADMIN') && <ul className="nav">
					<li className="nav-item"><Link className="nav-link active" to="/" aria-current="page"><i className="fa fa-dashboard"></i> Dashboard</Link></li>
					<li className="nav-title">User Management</li>
					<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/create-user`}><i className="fa fa-plus-circle"></i> Create User </Link></li>
					<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/user-list`}><i className="fa fa-table"></i> User List</Link></li>

					<li className="nav-title">Voice Plans</li>
					<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/add-voice-plan`}><i className="fa fa-plus-circle"></i> Add Voice Plan</Link></li>
					<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/voice-plans`}><i className="fa fa-table"></i> View Voice Plans</Link></li>
				</ul>}

				{(props.app.role === 'ROLE_SADMIN') && <ul className="nav">
				<li className="nav-item"><Link className="nav-link active" to="/" aria-current="page"><i className="fa fa-dashboard"></i> Dashboard</Link></li>
					<li className="nav-title">User Management</li>
					<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/create-user`}><i className="fa fa-plus-circle"></i> Create User </Link></li>
					<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/user-list`}><i className="fa fa-table"></i> User List</Link></li>
					<Link className="nav-link" to={`${urlPrefix}/credits-history`}><i className="fa fa-table"></i> Credits History</Link>
					<li className="nav-title">CLI Management</li>
					<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/add-cli`}><i className="fa fa-plus-circle"></i> Add CLI</Link></li>
					<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/cli-list`}><i className="fa fa-table"></i> CLI List</Link></li>

					<li className="nav-title">Voice Plans</li>
					<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/add-voice-plan`}><i className="fa fa-plus-circle"></i> Add Voice Plan</Link></li>
					<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/voice-plans`}><i className="fa fa-table"></i> View Voice Plans</Link></li>

					<li className="nav-title">Voice Library</li>
					<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/voice-files`}><i className="fa fa-table"></i> Prompts for Approval</Link></li>
				</ul>}

			</div>
		</div>
	);

}

export default SideNav;
