import React, { Component } from 'react';
import { Button, Card, Col, Container, Form, InputGroup, Row } from 'react-bootstrap';
import { Fetcher } from '../../Helpers/fetcher.js';

import "./index.css";
class ResetPwd extends Component {
	constructor(props) {
		super(props);
		this.state = {
			username: '',
			showModal: false,
			modalHeading: 'Status',
			modalContent: ''
		}
	}

	setvalue = (e) => {
		this.setState({
			[e.target.name]: e.target.value,
		});
	}

	resetPwd = (e) => {
		e.preventDefault();
		const username = this.state.username;

		if (username === '') {
			alert('Please enter Username');
			this.setState({
				showModal: true,
				modalHeading: 'Error',
				modalContent: 'Please enter Username'
			})
		} else {
			const data = {
				username: username
			}
			return Fetcher.post(this.props.app.apiPath + '/api/obd/reset/pwd', { headers: { "Content-type": "application/json" }, method: 'POST', body: JSON.stringify(data) })
				.then(res => {

					console.log(res);
					let modalHeading;
					if (res.message === 'Parameters Incorrect') {
						modalHeading = 'Error';
						alert('Something went wrong');
						this.setState({
							showModal: true,
							modalHeading: modalHeading,
							modalContent: 'Wrong username'
						})
					} else if(res.message === 'Link sent to registered mail'){
						alert(res.message);
						window.open('','_self').close();
					}
					else {
						console.log(res.message);
						alert('Something went wrong');
						this.setState({
							showModal: true,
							modalHeading: 'Error',
							modalContent: res.message
						})
					}
				})
				.catch(err => {
					console.log('Error in ResetPwd', err)
					this.setState({
						showModal: true,
						modalHeading: 'Error',
						modalContent: err.message
					})
				});

		}
	}

	setMessageShow = (status) => {
		this.setState({
			showModal: status,
		});
	}


	render() {
		return (
			<div className="app flex-row align-items-center">
				<Container>
					<Row className="justify-content-center">
						<Col md="4">
							<Card className="p-4">
								<Card.Header>Reset Password</Card.Header>
								<Card.Body>
									<Form method="post" onSubmit={this.resetPwd}>
										<div className="login-logo text-center">
											{this.props.app.logo && <img className="navbar-brand-full" src={this.props.app.logo} alt="CoreUI Logo" />}
										</div>

										<p className="text-muted text-center"></p>
										<InputGroup className="mb-3">
											<div className="input-group-prepend"><span className="input-group-text"><i className="fa fa-user"></i></span></div>
											<Form.Control className="field-username" name="username" type="text" placeholder="Username" onChange={e => this.setvalue(e)} value={this.state.username} autoComplete="new-username" />
										</InputGroup>
										
										<Row>
											<Col xs="3">
												<Button type="submit" color="primary" className="text-center px-4 btn btn-primary btn-round">Submit</Button>
											</Col>
										</Row>
									</Form>
								</Card.Body>
							</Card>
						</Col>
					</Row>
				</Container>

			</div>
		);
	}
}

export default ResetPwd;