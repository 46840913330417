import React, { Component } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { Fetcher } from '../../Helpers/fetcher.js';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationListStandalone, SizePerPageDropdownStandalone } from 'react-bootstrap-table2-paginator';
import './index.css';
class Dashboard extends Component {
	constructor(props) {
		super(props);
		this.state = {
			userList: [],
			dashboardData: []
		}
	}
	componentDidMount() {
		const apiPath = this.props.app.apiPath;
		const userId = this.props.app.userId;
		Fetcher.get(apiPath + '/api/obd/dashboard/' + userId)
			.then(res => {
				this.setState({
					dashboardData: res.dbHeader
				})
			})
			.catch(err => { console.log('Error in fetching Dashboard Data', err) });
		this.getUsers();
	}

	getUsers = () => {
		const apiPath = this.props.app.apiPath;
		const userId = this.props.app.userId;
		Fetcher.get(apiPath + '/api/obd/dashboard/' + userId)
			.then(res => {
				this.setState({
					userList: res.dbUserWiseCampaigns,
					showModal: false,
					showAddCreditModal: false,
					showPwdModal: false,
					confirmDelete: false,
				})
			})
			.catch(err => { console.log('Error in fetching Dashboard Data', err) });
	}

	render() {
		const userList = this.state.userList;
		var columns;
		if (this.props.app.role === 'ROLE_SADMIN') {
			columns = [
				{
					dataField: 'username',
					text: 'Username',
					sort: true,
					searchable: true
				},
				{
					dataField: 'totalCampaigns',
					text: 'Running Campaigns',
					sort: true,
					searchable: true
				},];
		}
		else {
			columns = [
				{
					dataField: 'username',
					text: 'Username',
					sort: true,
					searchable: true
				},
				{
					dataField: 'totalCampaigns',
					text: 'Running Campaigns',
					sort: true,
					searchable: true
				},];
		}
		const users = Array.isArray(userList) ? userList.map((user, index) => {
			if (this.props.app.role === 'ROLE_SADMIN') {
				return {
					username: user.username,
					totalCampaigns: user.totalCampaigns
				};
			}
			else {
				return {
					username: user.username,
					totalCampaigns: user.totalCampaigns
				};
			}
		}) : []
		const { SearchBar, ClearSearchButton } = Search;

		const paginationOption = {
			custom: true,
			totalSize: users.length,
			sizePerPage: 10,
			sizePerPageList: [10, 25, 50, 100]
		};

		return (
			<div className="animated fadeIn">
				<Card>
					<Card.Header>Voice Dashboard</Card.Header>
					<Card.Body>
						{this.props.app.role === 'ROLE_USER' && <Row>
							<Col xs="12" sm="6" lg="3">
								<Card className="text-white bg-info dashboard-card">
									<Card.Body className="pb-0">
										<div className="dashboard-card-heading">Total Voice Campaigns</div>
										<div className="text-value dashboard-card-data">{this.state.dashboardData.totalVoiceCampaigns}</div>

									</Card.Body>
								</Card>
							</Col>
							<Col xs="12" sm="6" lg="3">
								<Card className="text-white bg-primary dashboard-card">
									<Card.Body className="pb-0">
										<div className="dashboard-card-heading">Total Calls</div>
										<div className="text-value dashboard-card-data">{this.state.dashboardData.totalCalls}</div>

									</Card.Body>
								</Card>
							</Col>
							<Col xs="12" sm="6" lg="3">
								<Card className="text-white bg-success dashboard-card">
									<Card.Body className="pb-0">
										<div className="dashboard-card-heading">Connected Calls</div>
										<div className="text-value dashboard-card-data">{this.state.dashboardData.connectedCalls}</div>

									</Card.Body>
								</Card>
							</Col>
							
							<Col xs="12" sm="6" lg="3">
								<Card className="text-white bg-info dashboard-card">
									<Card.Body className="pb-0">
										<div className="dashboard-card-heading">Balance Available(Rs)</div>
										<div className="text-value dashboard-card-data">{this.state.dashboardData.voiceCreditsAvailable}</div>
									</Card.Body>
								</Card>
							</Col>
						</Row>}
						{(this.props.app.role === 'ROLE_SADMIN') && <Row>
							<Col xs="12" sm="6" lg="3">
								<Card className="text-white bg-info dashboard-card">
									<Card.Body className="pb-0">
										<div className="dashboard-card-heading">Total Resellers</div>
										<div className="text-value dashboard-card-data">{this.state.dashboardData.resellers}</div>
									</Card.Body>
								</Card>
							</Col>

							<Col xs="12" sm="6" lg="3">
								<Card className="text-white bg-success dashboard-card">
									<Card.Body className="pb-0">
										<div className="dashboard-card-heading">Total End Users</div>
										<div className="text-value dashboard-card-data">{this.state.dashboardData.endUsers}</div>
									</Card.Body>
								</Card>
							</Col>
						</Row>}
						{(this.props.app.role === 'ROLE_ADMIN') && <Row>
							<Col xs="12" sm="6" lg="3">
								<Card className="text-white bg-info dashboard-card">
									<Card.Body className="pb-0">
										<div className="dashboard-card-heading">Total Resellers</div>
										<div className="text-value dashboard-card-data">{this.state.dashboardData.resellers}</div>

									</Card.Body>
								</Card>
							</Col>
							<Col xs="12" sm="6" lg="3">
								<Card className="text-white bg-info dashboard-card">
									<Card.Body className="pb-0">
										<div className="dashboard-card-heading">Total End Users</div>
										<div className="text-value dashboard-card-data">{this.state.dashboardData.endUsers}</div>

									</Card.Body>
								</Card>
							</Col>

							<Col xs="12" sm="6" lg="3">
								<Card className="text-white bg-success dashboard-card">
									<Card.Body className="pb-0">
										<div className="dashboard-card-heading">Credits Available</div>
										<div className="text-value dashboard-card-data">{this.state.dashboardData.voiceCreditsAvailable}</div>
									</Card.Body>
								</Card>
							</Col>
						</Row>}
						<Row>
				<Col>
					<Card>
						{<Card.Header>Running Campaigns</Card.Header>}
						<Card.Body>

							<div className="user-list-table-cont">
								{<PaginationProvider pagination={paginationFactory(paginationOption)}>
									{
										({
											paginationProps,
											paginationTableProps
										}) => (
											<ToolkitProvider
												keyField="username"
												data={users}
												columns={columns}
												search
												bootstrap4

											>
												{
													props => (
														<div className="">
															Show <SizePerPageDropdownStandalone className="search-dropdown" {...paginationProps} /> items
															<span className="search-cont"><SearchBar  {...props.searchProps} /></span>
															<ClearSearchButton className="btn-primary btn-round" {...props.searchProps} />
															{/*<span>&nbsp;&nbsp;&nbsp;</span><span className="float-none avail-credit-cont"><span className="credits-avail">Available Credits: {this.state.adminCredits}</span></span>*/}
															<hr />
															<BootstrapTable bordered={true} striped={true} condensed wrapperClasses="table-responsive" classes="userlist-table"  {...props.baseProps}  {...paginationTableProps} />
															<PaginationListStandalone {...paginationProps} />

														</div>
													)
												}
											</ToolkitProvider>
										)
									}
								</PaginationProvider>}
							</div>
						</Card.Body>
					</Card>
				</Col>
			</Row>
					</Card.Body>
				</Card>
			</div>
		);
	}

}

export default Dashboard;